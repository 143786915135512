import {useContext} from "react";
import ConfigContext from "../store/Context/ConfigContext";

export const useAuthHeaders = () => {
    const config = useContext(ConfigContext);
    const jwtToken = sessionStorage.getItem('jwtToken');

    return {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Figaro: config.API_REQUEST_HEADER,
    };
};