import {Typography} from '@mui/material';
import NavGroup from './NavGroup';
import {useContext} from 'react';
import menuItems from "../../menu-items";
import {RolesContext} from "../../../store/Context/RolesContext";

const MenuList = () => {
  const { roles } = useContext(RolesContext);

  if (roles === undefined || roles.length === 0) {
    return (
        <Typography variant="h4" align="center" sx={{mt:2}}>
          Please select a client.
        </Typography>
    );
  }

  const navItems = menuItems.map((group) => {
    const filteredChildren = group.children.filter((item) => {
      if (!item.requiredRoles || item.requiredRoles.length === 0) {
        return true;
      }
      return item.requiredRoles.some((role) => roles.includes(role));
    });

    if (filteredChildren.length === 0) {
      return null;
    }

    return (
        <NavGroup
            key={group.id}
            item={{
              ...group,
              children: filteredChildren,
            }}
        />
    );
  });

  return <>{navItems}</>;
};

export default MenuList;
