import React, {useContext, useEffect, useState} from "react";

// MaterialUI imports
import {Box, Button, Container, IconButton, Modal, Paper, Typography} from "@mui/material";

// Project imports
import {ResponseDisplay} from "./ResponseDisplay";
import TransactionSummary from "../../ui-component/TransactionSummary";
import {useAuthHeaders} from "../AuthHeaders";
import {downloadPDF} from "../../store/PdfGenerator/downloadPDF";

// 3rd Party imports
import {IconX} from "@tabler/icons";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import ConfigContext from "../../store/Context/ConfigContext";

// ============================|| CREDIT - RESPONSE ||============================ //

const CreditResponse = ({response, open, clearResponse}) => {
    const navigate = useNavigate();
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);
    const clientId = sessionStorage.getItem('clientId');

    const fetchTransactionDetails = async (data) => {
        try {
            const response = await axios.post(`${config.API_URL}/figaro/v1/payment`, {
                clientId: clientId,
                request: {
                    creditReference: data.reference,
                },
            }, {
                headers: authHeaders,
            });

            setSelectedTransaction(response.data);

        } catch (error) {
            console.error("Error fetching transaction details:", error);
        }
    };

    useEffect( () => {
        const fetchData = async () => {
            await fetchTransactionDetails(response);

            setIsDataFetched(true)

            if (response?.state !== 'pending') {
                setIsDataFetched(false)
                response.error = {
                    message: 'The internal tracking reference you are trying to use already exists in our system.',
                    transactionSummaryExists: true
                };
            }            
        };

        if (response && response.reference) {
            fetchData();
        }

    }, [response]);

    const handleClose = () => {
        clearResponse();
        navigate('/');
    };

    return (
        isDataFetched && selectedTransaction ? (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="transfer-confirmation"
                aria-describedby="confirmation-of-transfer-details"
            >
                <Container
                    component={Paper}
                    sx={{
                        mt: 5,
                        py: 3,
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        position: 'relative',
                        borderRadius: '16px',
                    }}
                >
                    <IconButton
                        color="primary"
                        sx={{position: 'absolute', top: 5, right: 5}}
                        onClick={handleClose}
                    >
                        <IconX size={32}/>
                    </IconButton>

                    <Typography variant="h3" gutterBottom sx={{ml: 1, mt: 1, mb: 3}}>
                        Transfer Confirmation
                    </Typography>

                    <TransactionSummary selectedTransaction={selectedTransaction}/>

                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                            size="large"
                            variant="contained"
                            color="secondary"
                            onClick={() => downloadPDF(selectedTransaction)}
                        >
                            Download as PDF
                        </Button>
                        <Button size="large" variant="contained" color="primary" sx={{ml: 2}} onClick={handleClose}>
                            Close
                        </Button>
                    </Box>
                </Container>
            </Modal>
        ) : <ResponseDisplay response={response} selectedTransaction={selectedTransaction} typeOfRequest={"Transaction"}/>
    );
}

export default CreditResponse;
