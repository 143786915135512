import React from 'react';
import Chip from 'ui-component/extended/Chip';

function TransactionStatusChip({ state }) {
    let chipLabel;
    let chipColor = "default";

    switch (state) {
        case "complete":
            chipLabel = "Complete";
            chipColor = "success";
            break;
        case "sent":
            chipLabel = "Sent";
            chipColor = "warning";
            break;
            case "pending":
            chipLabel = "Pending";
            chipColor = "warning";
            break;
        case "rejected":
            chipLabel = "Rejected";
            chipColor = "error";
            break;
            case "canceled":
            chipLabel = "Canceled";
            chipColor = "error";
            break;
            case "reversed":
            chipLabel = "Reversed";
            chipColor = "error";
            break;
        default:
            chipLabel = "Unknown State";
            break;
    }

    return (
        <Chip label={chipLabel} variant="outlined" size="small" chipcolor={chipColor} />
    );
}

export default TransactionStatusChip;