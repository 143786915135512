import React, {useContext, useState} from "react";
import {Button, IconButton, Tooltip} from "@mui/material";
import {AuthContext} from "../../auth/AuthContext";
import {useFormikContext} from "formik";
import {IconRefreshAlert} from "@tabler/icons";

const APIRequestButton = ({
                              isSubmitting,
                              isSubmitted: initialIsSubmitted,
                              showRefreshButton,
                              onSubmitError,
                          }) => {
    const {isAuthenticated} = useContext(AuthContext);
    const {handleSubmit, errors} = useFormikContext();
    const [isSubmitted, setIsSubmitted] = useState(initialIsSubmitted);
    const [refreshed, setRefreshed] = useState(false);

    const handleClick = async () => {
        if (!isSubmitted && isAuthenticated) {
            try {
                await handleSubmit();
                setRefreshed(false);
            } catch (error) {
                // Handle submission errors, e.g., display an error message
                onSubmitError(error);
            }
        }
    };

    const handleRefresh = () => {
        setIsSubmitted(false); // Allow submission again
        setRefreshed(!refreshed);
    };

    return (
        <>
            <Button
                sx={{mt: 0.5}}
                variant="contained"
                type="button"
                disabled={!isAuthenticated || isSubmitting || (isSubmitted && !refreshed) || Object.keys(errors).length > 0}
                onClick={handleClick}
            >
                Submit
            </Button>
            {showRefreshButton && isSubmitted && (
                <Tooltip title="Unlock the form for editing or resubmission">
                    <IconButton onClick={handleRefresh}>
                        <IconRefreshAlert/>
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default APIRequestButton;
