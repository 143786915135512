const today = new Date();
export const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()} -${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}`;

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function formatDateToMonthDayYearWritten(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const monthName = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');

    return `${monthName}-${day}-${year}`;
}

export function formatDateToMonthDayYearWithTime(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const monthName = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${monthName} ${day}, ${year} @ ${hours}:${minutes}`;
}

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 2);
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

export const yearMonthDayFormattedDate = `${year}-${month}-${day}`;



export function formatDateToYearMonthDay(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function formatDateToMonthDayYear(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${month}-${day}-${year}`;
}