import {useEffect, useState} from 'react';
import {allCountries} from 'country-region-data';

export const useCountryRegions = (handleChange) => {
    const [regions, setRegions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const countries = allCountries.map(countryArray => ({
        value: countryArray[1],
        label: countryArray[0]
    }));

    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        const selectedCountryOption = countries.find(country => country.value === selectedValue);
        setSelectedCountry(selectedCountryOption);
        handleChange(event);
    };

    useEffect(() => {
        if (selectedCountry) {
            const countryData = allCountries.find(countryArray => countryArray[1] === selectedCountry.value);
            if (countryData) {
                const newRegions = countryData[2].map(regionArray => ({value: regionArray[1], label: regionArray[0]}));
                setRegions(newRegions);
            } else {
                setRegions([]);
            }
        }
    }, [selectedCountry]);

    return {countries, regions, handleCountryChange};
}