// 3rd Party Imports
import pdfGenerator from "./pdfGenerator";

// ==============================|| DOWNLOAD PDF ||============================== //

export const downloadPDF =  async (selectedTransaction) => {

    const transactionId = selectedTransaction.credit.id
    const {arrayBuffer, fileNamePrefix} = await pdfGenerator(selectedTransaction)

    // using the array buffer, create a `Blob` and a download URL
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob);

    // simulate a click event to trigger the download in the browser
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileNamePrefix}${transactionId}.pdf`;
    link.click();
}
