import React, {useContext, useEffect, useState} from 'react';

// material-ui
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {styled} from "@mui/system";

// project imports
import TransactionSummary from "../ui-component/TransactionSummary";
import RefreshQueryToolbar from "../ui-component/datagrid/RefreshQueryToolbar";
import ConfigContext from "../store/Context/ConfigContext";
import {yearMonthDayFormattedDate} from "../store/getDate";
import {useAuthHeaders} from "./AuthHeaders";
import {transactionHistoryColumns} from "../ui-component/datagrid/transactionHistoryColumns";
import CircularSpinner from "../ui-component/CircularSpinner";
// third-party
import axios from 'axios';
import {useClientAccount} from "../store/Context/ClientAccountContext";
import {RolesContext} from "../store/Context/RolesContext";


// ============================|| Material UI TableGrid Style  ||============================ //
export const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-toolbarContainer': {
        marginBottom: '16px',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary.main,
        fontWeight: 'bold',
        color: '#FFF',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '.75rem',
        },
    },
    '& .MuiDataGrid-sortIcon': {
        color: '#FFF', // white color
    },
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root': {
        color: '#FFF',
    },

}));


// ============================|| Transaction History Page  ||============================ //

const Transactions = () => {
    const [data, setData] = useState([]);
    const authHeaders = useAuthHeaders();
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const config = useContext(ConfigContext);
    const {clientId} = useClientAccount();
    const roles = useContext(RolesContext);

    useEffect(() => {
        async function fetchTransactionHistory() {
            try {
                const response = await axios.post(`${config.API_URL}/figaro/v1/history`, {
                    clientId: clientId,
                    request: {
                        begin: '2023-10-18',
                        end: yearMonthDayFormattedDate,
                    }
                }, {
                    headers: authHeaders,
                });

                const processedTransactions = (response.data.ledger?.transactions || [])
                    .filter(tx => ['debit', 'credit'].includes(Object.keys(tx)[0]))
                    .map((tx, index) => {
                        let extractedData = extractTransactionData(tx);
                        extractedData.id = index;
                        return extractedData;
                    });
                setData(processedTransactions.reverse());
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.href = '/token-expiry';
                } else {
                    console.error("An error occurred:", error);
                }
            } finally {
                setLoading(false);
            }
        }

        fetchTransactionHistory();
    }, [refreshFlag, clientId]);

    const extractTransactionData = (transaction) => {
        let type = Object.keys(transaction)[0];
        let data = transaction[type];

        let id, reference, estimatedAt, state, amount, currency, createdAt;

        switch (type) {
            case 'debit':
            case 'credit':
            case 'transfer':
                estimatedAt = data.estimatedAt;
                reference = data.reference;
                state = data.state;
                id = data.id;
                createdAt = data.created


                if (data.fx && data.fx.amount && data.fx.currency) {
                    amount = data.fx.amount;
                    currency = data.fx.currency;
                } else {
                    amount = data.amount;
                    currency = data.currency;
                }
                break;

            default:
                return null;
        }

        return {
            type,
            reference,
            state,
            estimatedAt,
            amount,
            currency,
            id,
            createdAt
        };
    }
    const handleRowClickForTransactionDetailModal = async (reference) => {
        try {
            const response = await axios.post(`${config.API_URL}/figaro/v1/payment`, {
                clientId: clientId,
                request: {
                    creditReference: reference,
                }
            }, {
                headers: authHeaders,
            });

            setSelectedTransaction(response.data);
            setModalOpen(true);
        } catch (error) {
            console.error("Error fetching transaction details:", error);
        }
    };
    const handleRefresh = () => {
        setRefreshFlag(!refreshFlag);
    };


    return (
        <div style={{height: 600, width: '100%', marginTop: "2vh"}}>
            <Typography variant={"h2"} sx={{mb: 3}}>Transaction History</Typography>
            {loading ? (
                <CircularSpinner/>
            ) : (
                <>
                <StyledDataGrid
                        rows={data}
                        columns={transactionHistoryColumns}
                        onRowClick={(param) => {
                            handleRowClickForTransactionDetailModal(param.row.reference);
                        }}
                    slots={{
                            toolbar: () => <RefreshQueryToolbar onRefresh={handleRefresh} data={data}/>,
                        }}
                        sx={{
                            boxShadow: 5,
                            border: 2,
                            borderColor: 'primary.light',
                            backgroundColor: '#fff',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    type: false,
                                },
                            },
                            sorting: {
                                sortModel: [{ field: 'createdAt', sort: 'desc' }],
                            },

                        }}
                    />
                    <Dialog
                        open={isModalOpen}
                        onClose={() => setModalOpen(false)}
                        fullWidth
                        maxWidth={"md"}
                    >
                        <DialogContent>
                            <TransactionSummary selectedTransaction={selectedTransaction}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setModalOpen(false)} color="secondary" variant={"contained"}>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </div>
    );
}

export default Transactions;
