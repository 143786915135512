import React, {useContext, useEffect, useState} from 'react';

// materialUI imports
import {Box} from '@mui/material';

// project imports
import {useAuthHeaders} from '../AuthHeaders';
import ConfigContext from "../../store/Context/ConfigContext";
import CreditResponse from "../response/CreditResponse";
import CreditForm from "./CreditForm";
import {formattedDate} from "../../store/getDate";
import useEntityDetails from "../../store/Hooks/useEntityDetails";

// 3rd Party imports
import * as Yup from 'yup';
import {Formik} from 'formik';
import useSubmitCredit from "../../store/Hooks/useSubmitCredit";

// ============================|| Create - Credit ||============================ //

const CreateUnknownCurrencyCredit = ({LedgerId, EntityId, fxDealDetails}) => {
    const responseRef = React.useRef(null);
    const [paymentType] = useState(fxDealDetails.response.id ? "Foreign" : "Domestic");
    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [confirmationData, setConfirmationData] = useState("");
    const {entityDetails} = useEntityDetails(EntityId);

    const {
        handleSubmit: handleCreditSubmit,
        response: creditResponse
    } = useSubmitCredit(authHeaders, config, paymentType);

    const handleConfirmationDataChange = (data) => {
        setConfirmationData(data)
        console.log(confirmationData)
    }


    useEffect(() => {
        if (creditResponse) {
            setResponseModalOpen(true);
        }
    }, [creditResponse]);

    const clearCreditResponse = () => {
        setResponseModalOpen(false);
        setConfirmationData(null);
    };

    const getCurrentFormattedDate = () => {
        const today = new Date();
        return `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()} -${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}`;
    };

    return (
        <Box mt={2}>
            <Formik
                initialValues={{
                    reference: `${EntityId} - ${getCurrentFormattedDate()}`,
                    account: undefined,
                    amount:  fxDealDetails.request.sellAmount,
                    currency: 'USD',
                    entityId: EntityId,
                    when: 'standard',
                    description: '',
                    fxQuoteId: fxDealDetails.response.id ? fxDealDetails.response.id: null,
                }}
                validationSchema={Yup.object().shape({
                    reference: Yup.string().required('Internal Tracking Reference is required'),
                    account: Yup.string(),
                    amount: Yup.number().required('Amount is required'),
                    currency: Yup.string().required('Currency is required'),
                    entityId: Yup.string().required('Entity Reference is required'),
                    when: Yup.string().required('When is required'),
                    description: Yup.string()
                        .required('Description is required')
                        .max(35, 'Description must be a maximum of 35 characters'),
                })}

                onSubmit={async (values, { setSubmitting }) => {
                    const formData = {
                        ...values,
                        totp: values.totpValue
                    };
                    await handleCreditSubmit(formData, { setSubmitting });
                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      touched,
                      errors,
                      setSubmitting,
                      validateForm,
                      setTouched
                  }) => (<CreditForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        validateForm={validateForm}
                        setTouched={setTouched}
                        requestData={fxDealDetails}
                        onConfirmationDataChange={handleConfirmationDataChange}
                        handleSubmit={handleSubmit}
                        setSubmitting={setSubmitting}
                        entityDetails={entityDetails}
                        fxDealDetails={fxDealDetails}
                    />
                )}
            </Formik>

            {creditResponse && (
                <Box mt={2} ref={responseRef}>
                    <CreditResponse
                        response={creditResponse}
                        open={responseModalOpen}
                        clearResponse={clearCreditResponse}/>
                </Box>
            )}
        </Box>

    );
};

export default CreateUnknownCurrencyCredit;
