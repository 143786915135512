import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [jwtToken, setJwtToken] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userSub, setUserSub] = useState(null);

    useEffect(() => {
        const storedToken = sessionStorage.getItem('jwtToken');
        if (storedToken) {
            setJwtToken(storedToken);
            setIsAuthenticated(true);
            parseJwt(storedToken);
        }
    }, []);

    const parseJwt = (token) => {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            const { sub } = JSON.parse(jsonPayload);
            if(sub) {
                setUserSub(sub);
            } else {
                console.error("Sub claim is missing in JWT.");
            }
        } catch (error) {
            console.error("Error parsing JWT", error);
        }
    };

    const setAuthToken = (token) => {
        setJwtToken(token);
        sessionStorage.setItem('jwtToken', token);
        parseJwt(token);
    };

    const removeAuthToken = () => {
        setJwtToken(null);
        sessionStorage.clear();
        setIsAuthenticated(false);
        setUserSub(null);
    };

    const handleTokenExpiry = () => {
        removeAuthToken()
    }

    return (
        <AuthContext.Provider
            value={{
                jwtToken,
                setAuthToken,
                removeAuthToken,
                isAuthenticated,
                setIsAuthenticated,
                handleTokenExpiry,
                userSub
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};