// useSubmitCredit.js

import axios from 'axios';
import {useState} from 'react';

const useSubmitCredit = (authHeaders, config, paymentType) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [response, setResponse] = useState(null);
    const clientId = sessionStorage.getItem('clientId');

    const handleTransactionSubmit = async (values, { setSubmitting }) => {

        const requestData = {
            clientId: clientId,
            request: {
                reference: values.reference,
                ledgerId: values.ledgerId,
                amount: parseFloat(values.amount).toFixed(2),
                currency: values.currency,
                entityId: values.entityId,
                when: values.when,
                text: { description: values.description },
            },
            totp: values.totp
        };

        if (values.account) {
            requestData.request.account = values.account;
        }
        if (paymentType) {
            requestData.request.fxQuoteId = values.fxQuoteId;
            requestData.request.quoteCurrency = values.quoteCurrency;
        }

        try {
            const options = {
                method: 'POST',
                url: `${config.API_URL}/figaro/v1/payment`,
                headers: authHeaders,
                data: requestData,
            };

            const response = await axios.request(options);
            setResponse(response.data);
            setIsSubmitted(true);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            }
            console.error('Error:', error);
        } finally {
            if (setSubmitting) {
                setSubmitting(false);
            }
        }
    };

    return {
        handleSubmit: handleTransactionSubmit,
        isSubmitted,
        response,
    };
};

export default useSubmitCredit;
