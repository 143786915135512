import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useFormikContext } from 'formik';

const CustomCurrencyValueInput = ({ name, label, touched, errors, disabled, value }) => {
    const { setFieldValue } = useFormikContext();

    const handleValueChange = (values) => {
        const strippedValue = values.value !== undefined ? String(values.value).replace(/,/g, '') : '';
        setFieldValue(name, strippedValue);
    };

    return (
        <Box>
            <FormControl fullWidth variant="outlined" error={touched[name] && Boolean(errors[name])} sx={{ mt: 1 }} disabled={disabled}>
                <InputLabel htmlFor={name}>{label}</InputLabel>
                <NumericFormat
                    id={name}
                    name={name}
                    allowEmptyFormatting={true}
                    customInput={OutlinedInput}
                    sx={{ padding: '5.5px 11.5px!important' }}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    label={label}
                    onValueChange={handleValueChange}
                    disabled={disabled}
                    value={value}
                />
                {touched[name] && errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
            </FormControl>
        </Box>
    );
};


export default CustomCurrencyValueInput;
