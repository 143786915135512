import React, {useEffect, useState} from "react";
// material-ui
import {Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select,} from "@mui/material";

// project imports
import {useBeneficiaries} from "../../store/Context/EntityContext";
import useEntityDetails from "../../store/Hooks/useEntityDetails";
import {renderBankDetails} from "../../store/renderBankDetails";
import MainCard from "../cards/MainCard";

// assets


// ==============================|| CreateBeneficiary Step of the Make Payment Flow ||============================== //
const BeneficiaryStepper = ({handleNext, handleBeneficiarySelection}) => {
    const [beneficiary, setBeneficiary] = useState("");
    const { entityData: beneficiaries, triggerReload } = useBeneficiaries();
    const {entityDetails} = useEntityDetails(beneficiary);

    const beneficiaryOptions = Array.isArray(beneficiaries)
        ? beneficiaries
            .filter(entity => entity.state === 'ready')
            .map(entity => ({
                value: entity.reference,
                label: entity.reference,
            }))
        : [];

    useEffect(() => {
        if (Array.isArray(beneficiaries) && beneficiaries.length === 0) {
            triggerReload();
        }
    }, [beneficiaries]);

    const handleChange = (event) => {
        const selectedBeneficiary = event.target.value;
        setBeneficiary(selectedBeneficiary);
        handleBeneficiarySelection(selectedBeneficiary);
    };

    return (
        <Grid container spacing={4} mt={4}>
            <Grid item xs={12}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="column" justifyContent="space-between"
                                     height="280px">
                                    <Box mb={3}>
                                        <h2>Choose Your Beneficiary</h2>
                                        <p>Please select from the available options.</p>
                                    </Box>

                                    <FormControl
                                        variant="outlined"
                                        sx={{
                                            width: "100%",
                                            mb: 3,
                                        }}
                                    >
                                        <InputLabel id="beneficiary-label"
                                                    sx={{fontSize: '1rem'}}>Select Beneficiary</InputLabel>
                                        <Select
                                            labelId="beneficiary-label"
                                            id="beneficiary"
                                            name="beneficiary"
                                            label="CreateBeneficiary"
                                            value={beneficiary}
                                            onChange={handleChange}
                                            sx={{fontSize: '1rem'}}
                                        >
                                            {beneficiaryOptions.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={!beneficiary}
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {entityDetails &&
                                    <MainCard title={"View Bank Details"} content>
                                        {renderBankDetails(entityDetails.details, entityDetails.details.name)}
                                    </MainCard>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default BeneficiaryStepper;
