import React, {useState} from 'react';
import MainCard from "../ui-component/cards/MainCard";
import RolesDataGrid from "../ui-component/RolesTable/RolesDataGrid";
import RoleSelectionModal from "../ui-component/extended/RolesSelectionModal";

// ============================|| USER TABLE DISPLAY ||============================ //
const RolesList = () => {
    const [isGrantModalOpen, setIsGrantModalOpen] = useState(false);
    const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleGrantClick = (userData) => {
        setSelectedUser(userData);
        setIsGrantModalOpen(true);
    };
    const handleRevokeClick = (userData) => {
        setSelectedUser(userData);
        setIsRevokeModalOpen(true);
    };

    const handleGrantCloseModal = () => {
        setIsGrantModalOpen(false);
        setSelectedUser(null);
    };
    const handleRevokeCloseModal = () => {
        setIsRevokeModalOpen(false);
        setSelectedUser(null);
    };
    return (
        <MainCard
            title={
                ""
            }
            content={false}
        >
            <RolesDataGrid onEditClick={handleGrantClick} onRevokeClick={handleRevokeClick} />
            {isGrantModalOpen && (
                <RoleSelectionModal
                    open={isGrantModalOpen}
                    onClose={handleGrantCloseModal}
                    user={selectedUser}
                    actionType={"grant"}
                />
            )}
            {isRevokeModalOpen && (
                <RoleSelectionModal
                    open={isRevokeModalOpen}
                    onClose={handleRevokeCloseModal}
                    user={selectedUser}
                    actionType={"revoke"}
                />
            )}
        </MainCard>
    );
};

export default RolesList;