import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import axios from "axios";
import {useAuthHeaders} from "../../requests/AuthHeaders";
import ConfigContext from "../../store/Context/ConfigContext";
import {RolesColumns} from "./RolesColumns";
import React, {useContext, useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useLocation} from "react-router-dom";


export const CustomRolesToolBar = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                pb: 1,
                pl: 2,
                pr: 2,
                pt: 2,
                '& .MuiFormControl-root > .MuiInputBase-root': {
                    p: 0.6,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: 2,
                    background: theme.palette.grey[50]
                },
                '& .MuiFormControl-root > .MuiInputBase-root:after': {
                    display: 'none'
                },
                '& .MuiFormControl-root > .MuiInputBase-root:before': {
                    display: 'none'
                },
                '& .MuiFormControl-root > .Mui-focused': {
                    border: `1px solid ${theme.palette.primary.main}`
                },
                '& .MuiSvgIcon-root': {
                    color: `${theme.palette.primary.main} !important`,
                },

            }}
        >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h3">Permissions and Roles</Typography>
                </Grid>
                <GridToolbarQuickFilter/>
            </Grid>
        </Box>
    );
};

// ==============================|| USER ROLES DATA GRID  ||============================= //

const RolesDataGrid = ({ onEditClick, onRevokeClick }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);
    const realm = sessionStorage.getItem("clientId");
    const location = useLocation();
    const email = location.state?.email;

    useEffect(() => {
         const apiUrl = `${config.API_URL}/login/v1/users`;
        const requestData = {
            realm: realm,
        };

        axios.post(apiUrl, requestData, {
            headers: authHeaders,
        })

            .then((response) => {
                const updatedData = response.data.users.map((user) => ({
                    ...user,
                    id: user.reference,
                    payment_create: user.roles.some(role => role.role === 'payment_create'),
                    beneficiary_create: user.roles.some(role => role.role === 'beneficiary_create'),
                    quote: user.roles.some(role => role.role === 'quote'),
                    invite: user.roles.some(role => role.role === 'invite'),
                    grant: user.roles.some(role => role.role === 'grant'),
                    revoke: user.roles.some(role => role.role === 'revoke'),
                }));
                setData(updatedData);
                setLoading(false);
            })

            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.href = '/token-expiry';
                }
                console.error('Error fetching data:', error);
                setLoading(false);
            });

    }, [onEditClick, onRevokeClick]);

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div style={{height: '80vh', width: '100%'}}>
            <DataGrid
                rows={data}
                columns={RolesColumns(onEditClick, onRevokeClick)}
                slots={{
                    toolbar: CustomRolesToolBar
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    filter:{
                        filterModel:{
                            items: [],
                            quickFilterValues: [email]
                        }
                    }
                }}
                pageSizeOptions={[5, 10, 25]}
            />


        </div>
    );
};

export default RolesDataGrid