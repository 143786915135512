// assets
import {IconReplace, IconRoute, IconUserPlus, IconUsers, IconWallet, IconSquareRoundedPlus, IconListSearch} from '@tabler/icons';

const icons = { IconUserPlus, IconUsers, IconReplace, IconWallet, IconRoute, IconSquareRoundedPlus, IconListSearch };

const menuItems = [
  {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
      {
        id: 'payment',
        title: 'Make Payment',
        type: 'item',
        url: '/payment',
        icon: icons.IconReplace,
        requiredRoles: ['payment_create'],
        breadcrumbs: false,
      },
      {
        id: 'quote',
        title: 'Get a FX Quote',
        type: 'item',
        url: '/quote',
        icon: icons.IconRoute,
        requiredRoles: ['quote'],
        breadcrumbs: false,
      },
      {
        id: 'transaction',
        title: 'Transaction History',
        type: 'item',
        url: '/transaction-history',
        icon: icons.IconWallet,
        requiredRoles: [],
        breadcrumbs: false,
      },
    ],
  },
  {
    id: 'actions',
    title: '',
    caption: '',
    type: 'group',
    children: [
      {
        id: 'createBeneficiary',
        title: 'Create Beneficiary',
        type: 'item',
        url: '/create-beneficiary',
        icon: icons.IconUserPlus,
        requiredRoles: ['beneficiary_create'],
        breadcrumbs: false,
      },
      {
        id: 'viewBeneficiary',
        title: 'View Beneficiaries',
        type: 'item',
        url: '/view-beneficiary',
        icon: icons.IconUsers,
        requiredRoles: [],
        breadcrumbs: false,
      },
    ],
  },
  {
    id: 'users',
    title: '',
    caption: '',
    type: 'group',
    children: [
      {
        id: 'inviteUser',
        title: 'Invite User',
        type: 'item',
        url: '/invite-user',
        icon: icons.IconSquareRoundedPlus,
        requiredRoles: ['invite'],
        breadcrumbs: false,
      },
      {
        id: 'userLists',
        title: 'View Users',
        type: 'item',
        url: '/users',
        icon: icons.IconListSearch,
        requiredRoles: ['grant', 'revoke'],
        breadcrumbs: false,
      },
    ],
  },
];

export default menuItems;
