// MaterialUI Imports
import {Stack, Typography} from "@mui/material";

// ==============================|| Utility FUnction for  Bank Details ||============================== //
export const reformatDetailsForRendering = (data) => {
    let formattedDetails = {};

    switch (data.InfoType) {
        case 'ach':
            formattedDetails.ach = {
                aba: data.aba,
                type: data.achType,
                account: data.account
            };
            break;
        case 'visab2b':
            formattedDetails.visab2b = {
                bic: data.bic,
                account: data.account,
                address: {
                    street1: data.street1,
                    city: data.city,
                    country: data.country,
                    regionCode: data.state,
                    postalCode: data.postalCode
                }
            };
            break;
        case 'interLedger':
            formattedDetails.interledger = {
                paymentPointer: data.paymentPointer,
                address: {
                    street1: data.street1,
                    city: data.city,
                    country: data.country,
                    regionCode: data.state,
                }
            };
            break;
        default:
            // Handle default case if necessary
            break;
    }

    return formattedDetails;
};


// ==============================|| RENDER Bank Details ||============================== //
export const renderBankDetails = (details, receiverName) => {
    let detailType;

    if (details?.ach) {
        detailType = "ach";
    } else if (details?.visab2b) {
        detailType = "visab2b";
    } else if (details?.interledger) {
        detailType = "interledger";
    }

    switch (detailType) {
        case "ach":
            return (
                <Stack spacing={2}>
                    <Typography variant="h4">{receiverName} Bank Details</Typography>
                    <Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">Aba: </Typography>
                            <Typography variant="body2">{details.ach?.aba}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">Bank Account Type:</Typography>
                            <Typography variant="body2">{details.ach?.type}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">Account Number:</Typography>
                            <Typography variant="body2">{details.ach?.account}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            );

        case "visab2b":
            return (
                <Stack spacing={2}>
                    <Typography variant="h4">{receiverName} Bank Details</Typography>
                    <Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">BIC: </Typography>
                            <Typography variant="body2">{details.visab2b?.bic}</Typography>
                        </Stack>
                        {details.visab2b.account && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle1">Account: </Typography>
                                <Typography variant="body2">{details.visab2b?.account}</Typography>
                            </Stack>
                        )}
                        {details.visab2b.iban && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle1">Iban: </Typography>
                                <Typography variant="body2">{details.visab2b?.iban}</Typography>
                            </Stack>
                        )}
                        {details.visab2b.branch && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle1">Branch Name: </Typography>
                                <Typography variant="body2">{details.visab2b?.branch}</Typography>
                            </Stack>
                        )}
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle1">Address:</Typography>
                                <Typography variant="body2">{details.visab2b?.address?.street1}</Typography>
                            </Stack>

                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">City:</Typography>
                            <Typography variant="body2">{details.visab2b?.address?.city}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">Country:</Typography>
                            <Typography variant="body2">{details.visab2b?.address?.country}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">State:</Typography>
                            <Typography variant="body2">{details.visab2b?.address?.regionCode}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">Zip code:</Typography>
                            <Typography variant="body2">{details.visab2b?.address?.postalCode}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            );
        case "interledger":
            return (
                <Stack spacing={2}>
                    <Typography variant="h4">{receiverName} Interledger Details</Typography>
                    <Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">Payment Pointer:</Typography>
                            <Typography variant="body2">{details.interledger?.paymentPointer}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">Address: </Typography>
                            <Typography variant="body2">{details.interledger?.address?.street1}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">City:</Typography>
                            <Typography variant="body2">{details.interledger?.address?.city}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">Country:</Typography>
                            <Typography variant="body2">{details.interledger?.address?.country}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="subtitle1">State:</Typography>
                            <Typography variant="body2">{details.interledger?.address?.regionCode}</Typography>
                        </Stack>

                    </Stack>
                </Stack>
            );

        default:
            return null;
    }
};