import React from "react";
import {Grid} from "@mui/material";

const Home = () => {

    return <Grid container justifyContent="center">
        <Grid item xs={12} sm={10}>

        </Grid>
    </Grid>
}
export default Home;
