import {IconCircleCheck, IconCircleX, IconMinus, IconPlus} from '@tabler/icons';
import {IconButton, Stack, Tooltip} from "@mui/material";
import {useContext} from "react";
import {RolesContext} from "../../store/Context/RolesContext";
import {formatDateToMonthDayYearWithTime} from "../../store/getDate";


// ============================ || Custom Dot Component || ============================ //
const Dot = ({color}) => (
    <div style={{
        height: '10px',
        width: '10px',
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
    }}/>
);

// ============================ || USER ROLES TABLE COLUMNS  || ============================ //
export const RolesColumns = (onEditClick, onRevokeClick) => {
    const { roles } = useContext(RolesContext);

    return [
        {
            field: 'totp_enabled',
            headerName: '',
            flex: .25,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.totp_enabled) {
                    return (
                        <Tooltip title={`Enrolled`}>
                            <IconButton>
                                <Dot color="green"/>
                            </IconButton>
                        </Tooltip>
                    )
                } else if (!params.row.totp_enabled && params.row.enrollment_expiry) {
                    return (
                        <Tooltip title={`Expiry: ${formatDateToMonthDayYearWithTime(params.row.enrollment_expiry)}`}>
                            <IconButton>
                                <Dot color="yellow"/>
                            </IconButton>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title={`Not Enrolled`}>
                            <IconButton>
                                <Dot color="red"/>
                            </IconButton>
                        </Tooltip>
                    );
                }
            },
        },
        {field: 'username', headerName: 'Email', flex: 1.25},
        {
            field: 'payment_create',
            headerName: 'Payment',
            flex: .75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.payment_create ? (
                    <IconCircleCheck style={{color: 'green'}}/>
                ) : (
                    <IconCircleX style={{color: 'red'}}/>
                )
            ),
        },
        {
            field: 'beneficiary_create',
            headerName: 'Beneficiary',
            flex: .75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.beneficiary_create ? (
                    <IconCircleCheck style={{color: 'green'}}/>
                ) : (
                    <IconCircleX style={{color: 'red'}}/>
                )
            ),
        },
        {
            field: 'quote',
            headerName: 'Quote',
            flex: .75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.quote ? (
                    <IconCircleCheck style={{color: 'green'}}/>
                ) : (
                    <IconCircleX style={{color: 'red'}}/>
                )
            ),
        },
        {
            field: 'invite',
            headerName: 'Invite',
            flex: .75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.invite ? (
                    <IconCircleCheck style={{color: 'green'}}/>
                ) : (
                    <IconCircleX style={{color: 'red'}}/>
                )
            ),
        },
        {
            field: 'grant',
            headerName: 'Grant',
            flex: .75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.grant ? (
                    <IconCircleCheck style={{color: 'green'}}/>
                ) : (
                    <IconCircleX style={{color: 'red'}}/>
                )
            ),
        },
        {
            field: 'revoke',
            headerName: 'Revoke',
            flex: .75,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.revoke ? (
                    <IconCircleCheck style={{color: 'green'}}/>
                ) : (
                    <IconCircleX style={{color: 'red'}}/>
                )
            ),
        },
        {
            field: 'actions',
            headerName: 'Manage Roles',
            flex: .75,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" justifyContent="center" alignItems="center">
                    {roles.includes("grant") && (
                        <Tooltip title={"Grant User Roles"}>
                            <IconButton
                                sx={{
                                    color: 'rgba(142,171,131,0.8)',
                                    borderColor: 'green',
                                    '&:hover ': {background: '#7eec39'},
                                }}
                                aria-label="message"
                                onClick={() => onEditClick(params.row)}
                            >
                                <IconPlus/>
                            </IconButton>
                        </Tooltip>
                    )}
                    {roles.includes("revoke") && (
                        <Tooltip title={"Revoke User Roles"}>
                            <IconButton
                                sx={{
                                    color: 'rgba(142,171,131,0.8)',
                                    borderColor: '#FF5722',
                                    '&:hover ': {background: '#FFCCBC'},
                                }}
                                aria-label="block"
                                onClick={() => onRevokeClick(params.row)}
                            >
                                <IconMinus/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            )
        },
    ]
};