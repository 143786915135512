// GetFxQuote.js
import React, {useContext, useEffect, useRef, useState} from "react";

// materialUI imports
import {Box, Grid, Modal, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";

// project imports
import APIRequestButton from "../ui-component/extended/APIRequestButton";
import CustomDropdown from "../ui-component/CustomDropdown";
import {useAuthHeaders} from "./AuthHeaders";
import {currencyOptions} from "../ui-component/CurrencyOptions";
import CircularSpinner from "../ui-component/CircularSpinner";
import {FadeInBox} from "../ui-component/extended/FadeInBox";
import FxQuoteResponseDisplay from "./response/FxQuoteResponseDisplay";
import {useBeneficiaries} from "../store/Context/EntityContext";
import ConfigContext from "../store/Context/ConfigContext";
import CreateUSDCredit from "./CreditRequest/CreateUSDCredit";

// 3rd Party imports
import axios from "axios";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {InfoText} from "../ui-component/extended/InfoText";
import {RolesContext} from "../store/Context/RolesContext";


// ============================|| GET FX QUOTE ||============================ //
const GetFxQuote = () => {
    const [response, setResponse] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [beneficiary, setBeneficiary] = useState("");
    const [isResponseReceived, setIsResponseReceived] = useState(false);
    const config = useContext(ConfigContext);
    const responseRef = useRef(null);
    const authHeaders = useAuthHeaders();
    const { entityData: beneficiaries, triggerReload } = useBeneficiaries();
    const theme = useTheme();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const clientId = sessionStorage.getItem("clientId");
    const {roles} = useContext(RolesContext)

    const beneficiaryOptions = Array.isArray(beneficiaries)
    ? beneficiaries
            .filter(entity => entity.state === 'ready')
            .map(entity => ({
                value: entity.reference,
                label: entity.reference,
            }))
        : [];
    beneficiaryOptions.sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        if (Array.isArray(beneficiaries) && beneficiaries.length === 0) {
            triggerReload();
        }
    }, [beneficiaries]);

    useEffect(() => {
        if (response && responseRef.current) {
            responseRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
            setIsResponseReceived(true);
        }
    }, [response]);

    const clearResponseAndError = () => {
        setResponse(null);
        setIsResponseReceived(false);
    };

    // --------------Quote Form Submission ---------------//

    const initialFormValues = {
        entityId: "",
        currency: "USD",
    };

    const handleGetQuoteSubmit = async (values) => {
        if (values.currency === "USD") {
            setIsModalOpen(true);
            clearResponseAndError();
            return;
        }
        const requestData = {
            clientId: clientId,
            request: {
                entityId: values.entityId,
                currency: values.currency,
                sellAmount: 1
            }

        };
        setIsLoading(true);
        clearResponseAndError();
        try {
            const response = await axios.post(`${config.API_URL}/figaro/v1/quote`, requestData, {
                headers: authHeaders,
            });
            setResponse({data: response.data, request: requestData});
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.location.href = '/token-expiry';
            }
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const filteredCurrencyOptions = currencyOptions.filter(option => option.value !== 'USD');

    // ----------------------------------------------//

    return (
        <Box mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={isResponseReceived ? 4 : 12}>
                    {isModalOpen ? (
                        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 'fit-content',
                                maxHeight: '90vh',
                                overflowY: 'auto',
                                bgcolor: 'white',
                                borderRadius: '16px',
                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                                p: 4,
                                outline: 'none',
                            }}>
                                <Typography variant={'h2'}> Book Transaction </Typography>
                                <CreateUSDCredit
                                    EntityId={beneficiary}
                                />
                            </Box>
                        </Modal>
                    ) : (
                        <Formik
                            initialValues={initialFormValues}
                            validationSchema={Yup.object().shape({
                                entityId: Yup.string()
                                    .trim()
                                    .notOneOf([""], "Entity cannot be empty")
                                    .required("Entity is required"),
                                currency: Yup.string().required("Currency is required"),
                            })}
                            onSubmit={handleGetQuoteSubmit}
                        >
                            {({values,
                                  handleChange,
                                  handleBlur,
                                  isSubmitting,
                                  touched,
                                  errors
                            }) => (
                                <Form>
                                    <div
                                        onClick={() => setShowInfo((prev) => !prev)}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            padding: "5px",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            component="h2"
                                            gutterBottom
                                            style={{marginRight: "8px"}}
                                        >
                                            Need Help?
                                        </Typography>
                                    </div>
                                    <InfoText text={"Currency you wish to send"} showInfo={showInfo}/>

                                        <CustomDropdown
                                            name="currency"
                                            label="Currency"
                                            value={roles.includes("quote") ? values.currency : "USD"}
                                            handleChange={(e) => {
                                                handleChange(e);
                                                clearResponseAndError();
                                            }}
                                            handleBlur={handleBlur}
                                            touched={touched}
                                            errors={errors}
                                            options={filteredCurrencyOptions}
                                            maxHeight={"50vh"}
                                            textSize={"1rem"}
                                            disabled={!roles.includes("quote")}
                                        />

                                    <InfoText text={"Beneficiary that funds will be sent to"} showInfo={showInfo}/>
                                    <CustomDropdown
                                        name="entityId"
                                        label="Beneficiary"
                                        value={values.entityId}
                                        handleChange={(e) => {
                                            handleChange(e);
                                            setBeneficiary(e.target.value);
                                            clearResponseAndError();
                                        }}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        maxHeight={"40vh"}
                                        options={beneficiaryOptions}
                                    />

                                    {isLoading ? (
                                        <CircularSpinner/>
                                    ) : (
                                        <APIRequestButton
                                            isSubmitting={isSubmitting}
                                            showRefreshButton={false}
                                        />
                                    )}
                                </Form>
                            )}
                        </Formik>
                    )}
                </Grid>
                <Grid item xs={12} lg={8}>
                    {response && (
                        <FadeInBox
                            ref={responseRef}
                            sx={{
                                height: "100%",
                                [theme.breakpoints.up("lg")]: {
                                    ml: 5,
                                },
                            }}
                        >
                            <FxQuoteResponseDisplay response={response.data} request={response.request}/>
                        </FadeInBox>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default GetFxQuote;