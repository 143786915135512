//3rd party imports
import {useSelector} from 'react-redux';
import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {Route, Routes} from "react-router-dom";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers'

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import MainLayout from "./layout";
import {AuthContext, AuthProvider} from "./auth/AuthContext";
import Welcome from "./views/Welcome";
import Quote from "./views/Quote";
import TransactionHistory from "./requests/TransactionHistory";
import Home from "./views/Home";
import {useContext} from "react";
import LoginScreen from "./views/LoginScreen";
import EnrollView from "./views/Enroll";
import LoginScreenWithMessage from "./views/LoginScreenWithMessage";
import CreateBeneficiary from "./views/CreateBeneficiary";
import ViewBeneficiaries from "./views/ViewBeneficiaries";
import ConfigContext from "./store/Context/ConfigContext";
import {RolesContext} from "./store/Context/RolesContext";
import QuoteAndPayment from "./views/QuoteAndPayment";
import RolesList from "./views/RolesList";
import Invite from "./views/Invite";
import MakePayment from "./requests/MakePayment";

// ==============================|| APP ||============================== //

const AppContent = () => {
    const {isAuthenticated} = useContext(AuthContext);
    const {roles} = useContext(RolesContext)

    if (isAuthenticated) {
        return (
            <MainLayout>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    {roles.includes("quote") && (
                        <Route path="/quote" element={<Quote/>}/>
                    )}
                    {roles.includes("beneficiary_create") && (
                        <Route
                            path="/create-beneficiary"
                            element={<CreateBeneficiary/>}
                        />
                    )}
                    {roles.includes("payment_create") && (
                        <Route path="/payment" element={<MakePayment/>}/>
                    )}
                    {roles.includes("invite") && (
                        <Route path="/invite-user" element={<Invite/>}/>
                    )}
                    {(roles.includes("revoke") || roles.includes("grant")) && (
                        <Route path="/users" element={<RolesList/>}/>
                    )}
                    <Route path="/token-expiry" element={<LoginScreenWithMessage snackbarType="token-expiry"/>}/>
                    <Route path="/transaction-history" element={<TransactionHistory/>}/>
                    <Route path="/view-beneficiary" element={<ViewBeneficiaries/>}/>
                    <Route path="*" element={<Welcome/>}/>
                </Routes>
            </MainLayout>
        );
    } else {
        return (
            <Routes>
                <Route path="/success-now-login" element={<LoginScreenWithMessage snackbarType="success"/>}/>
                <Route path="/enroll/:uuid" element={<EnrollView/>}/>
                <Route path="/login" element={<LoginScreen/>}/>
                <Route path="/token-expiry" element={<LoginScreenWithMessage snackbarType="token-expiry"/>}/>
                <Route path="/permission-denied" element={<LoginScreenWithMessage snackbarType="permissionDenied"/>}/>
                <Route path="*" element={<LoginScreen/>}/>
            </Routes>
        );
    }
};

const App = () => {
    const customization = useSelector((state) => state.customization);
    const webConfig = useContext(ConfigContext);
    const mergedThemeConfig = {
        ...customization,
        colors: webConfig?.colors || {}
    };

    return (
        <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AuthProvider>
                    <ThemeProvider theme={themes(mergedThemeConfig)}>
                        <CssBaseline/>
                        <NavigationScroll>
                            <AppContent/>
                        </NavigationScroll>
                    </ThemeProvider>
                </AuthProvider>
            </LocalizationProvider>
        </StyledEngineProvider>
    );
};

export default App;