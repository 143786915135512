import React, {useContext, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import {Alert, Box, Button, Container, Grid, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import ConfigContext from '../store/Context/ConfigContext';
import QRCode from 'qrcode-generator';
import {ErrorMessage, Form, Formik} from 'formik';
import OtpInput from "react18-input-otp";
import AnimateButton from "../ui-component/extended/AnimateButton";
import Logo from "../ui-component/Logo";

const extractAccountFromURL = (url) => {
    const match = url.match(/totp\/([^?]+)\?secret/);
    return match ? decodeURIComponent(match[1]) : null;
};
const extractSecretFromURL = (url) => {
    const match = url.match(/secret=([^&]*)/);
    return match ? match[1] : null;
};
const generateQRCode = (otpAuthURL) => {
    const qrCode = new QRCode(0, 'L');
    qrCode.addData(otpAuthURL);
    qrCode.make();

    return qrCode.createDataURL(8, 0);
};

const QRCodeGenerator = ({ otpAuthURL }) => {
    const [qrCodeData, setQRCodeData] = useState('');
    const [showSecret, setShowSecret] = useState(false);
    const extractedSecret = extractSecretFromURL(otpAuthURL);
    const extractedAccount = extractAccountFromURL(otpAuthURL); // Use the function to extract the account name

    useEffect(() => {
        const qrCode = generateQRCode(otpAuthURL);
        setQRCodeData(qrCode);
    }, [otpAuthURL]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center', // Center align the content
            }}
        >
            <Button
                size="large"
                variant="outlined"
                onClick={() => setShowSecret(!showSecret)}
                style={{ marginBottom: '10px', minWidth: '200px' }} // Set a minimum width for the button
            >
                {showSecret ? 'Hide Manual Entry' : 'See Manual Entry '}
            </Button>

            <Paper elevation={3}>
                <img src={qrCodeData} alt="QR Code" />
            </Paper>

            {showSecret && (
                <div>
                    {extractedAccount && (
                        <Typography
                            variant="subtitle2"
                            style={{
                                marginTop: '10px',
                                padding: '5px',
                                background: 'white',
                            }}
                        >
                            Account: {extractedAccount}
                        </Typography>
                    )}
                    {extractedSecret && (
                        <Typography
                            variant="subtitle2"
                            style={{
                                marginTop: '10px',
                                padding: '5px',
                                background: 'white',
                            }}
                        >
                            Setup Key: {extractedSecret}
                        </Typography>
                    )}
                </div>
            )}
        </Box>

    );
};

const EnrollView = () => {
    const { uuid } = useParams();
    const [otpAuthURL, setOTPAuthURL] = useState('');
    const [isSecretFetched, setIsSecretFetched] = useState(false);
    const [error, setError] = useState(null);
    const config = useContext(ConfigContext);
    const navigate = useNavigate();
    const [errorAlertMessage, setErrorAlertMessage] = useState(null);


    useEffect(() => {
        const fetchSecretData = async () => {
            try {
                const response = await axios.post(`${config.API_URL}/login/v1/enroll`, {
                    reference: uuid,
                });

                const totp = response.data.totp;

                if (totp) {
                    setOTPAuthURL(totp);
                    setIsSecretFetched(true);
                } else {
                    setError("Reference not found. Please contact support.");
                }
            } catch (error) {
                console.error("Error fetching the secret:", error);
                setError("An error occurred. Please try again later.");
            }
        };

        fetchSecretData();
    }, [uuid, config.API_URL]);

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const response = await axios.post(`${config.API_URL}/login/v1/enroll`, {
                reference: uuid,
                code1: values.code1,
                code2: values.code2,
            });

            if (response.data.error) {
                if (response.data.error.code === 'InvalidCodes' && response.data.error.message === null) {
                    setErrorAlertMessage('Codes Invalid. Please try again.');
                } else {
                    console.error('Error:', response.data.error.message);
                    setErrorAlertMessage(response.data.error.message);
                }
            } else {
                resetForm();
                navigate('/success-now-login');
            }
        }catch (error) {
            console.error('Error submitting the codes:', error);
        }
    };

    return (
        <Container maxWidth="lg">
            <Grid container direction="column" alignItems="center" spacing={1} style={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                    <Logo/>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    {error ? (
                            <Alert severity="error">{error}</Alert>
                        ) : isSecretFetched ? (
                            <QRCodeGenerator otpAuthURL={otpAuthURL} />
                        ) : null}

                </Grid>
                {isSecretFetched && (
                    <Formik
                        initialValues={{
                            code1: '',
                            code2: '',
                        }}
                        validate={(values) => {
                            const errors = {};
                            if (!values.code1) {
                                errors.code1 = 'Code 1 is required';
                            }
                            if (!values.code2) {
                                errors.code2 = 'Code 2 is required';
                            }
                            return errors;
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ handleBlur, handleChange, values }) => (
                            <Form>
                                    <Grid item sx={{ mt: 3 }}>
                                        <Typography variant="h5">
                                            Enter First 6-digit authentication code
                                        </Typography>
                                        <OtpInput
                                            value={values.code1}
                                            onChange={(code1) => {
                                                handleChange({ target: { name: 'code1', value: code1 } });
                                            }}
                                            numInputs={6}
                                            containerStyle={{
                                                justifyContent: 'space-between',
                                                fontSize: '16px',
                                            }}
                                            inputStyle={{
                                                width: '60px',
                                                height: '40px',
                                                margin: '8px',
                                                padding: '10px',
                                                border: `1px solid `,
                                                borderRadius: 4,
                                                ':hover': {
                                                    borderColor: "#000"
                                                }
                                            }}
                                            focusStyle={{
                                                outline: 'none',
                                                border: `2px solid #000`
                                            }}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage name="code1" component="div" style={{ color: 'red' }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5">
                                            Enter Second 6-digit authentication code
                                        </Typography>
                                        <OtpInput
                                            value={values.code2}
                                            onChange={(code2) => {
                                                handleChange({ target: { name: 'code2', value: code2 } });
                                            }}
                                            numInputs={6}
                                            containerStyle={{
                                                justifyContent: 'space-between',
                                                fontSize: '16px',
                                            }}
                                            inputStyle={{
                                                width: '60px',
                                                height: '40px',
                                                margin: '8px',
                                                padding: '10px',
                                                border: `1px solid `,
                                                borderRadius: 4,
                                                ':hover': {
                                                    borderColor: "#000"
                                                }
                                            }}
                                            focusStyle={{
                                                outline: 'none',
                                                border: `2px solid #000`
                                            }}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage name="code2" component="div" style={{ color: 'red' }} />
                                    </Grid>
                                  <Grid item container justifyContent="center">
                                        <AnimateButton>
                                            <Button variant="contained" color="primary" type="submit" size="large" sx={{ px: 4, minWidth: '200px' }}>
                                                Submit
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                            </Form>
                        )}
                    </Formik>
                )}

            </Grid>
            {errorAlertMessage && (
                <Alert
                    variant="filled" severity="error"
                    sx={{
                        position: 'fixed',
                        bottom: '10px',
                        left: '10px'
                    }}
                >
                    {errorAlertMessage}
                </Alert>
            )}
        </Container>
    );
};

export default EnrollView;
