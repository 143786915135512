import React, {useContext, useEffect, useState} from 'react';
import {Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel} from '@mui/material';
import axios from 'axios';
import {useAuthHeaders} from "../../requests/AuthHeaders";
import ConfigContext from "../../store/Context/ConfigContext";
import {AuthContext} from "../../auth/AuthContext";
import {availableRoles} from "../auth/availableRoles";
import {RolesContext} from "../../store/Context/RolesContext";
import {CheckBoxOutlineBlank, IndeterminateCheckBox} from "@mui/icons-material";

const RoleSelectionModal = ({open, onClose, user, actionType}) => {
    const {userSub} = useContext(AuthContext);
    const config = useContext(ConfigContext);
    const {setRolesDynamically} = useContext(RolesContext);
    const authHeaders = useAuthHeaders();
    const clientId = sessionStorage.getItem('clientId');
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [initialRoles, setInitialRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const shouldUpdateSessionAndContext =  user.reference === userSub;


    useEffect(() => {
        if (!userSub) {
            console.log('Waiting for userSub to be set...');
            return;
        }
        const initializeRoles = () => {
            const rolesFromUser = availableRoles.filter(role => user && user[role]);
            setInitialRoles(rolesFromUser);
            setSelectedRoles(actionType === 'grant' ? rolesFromUser : []);
        };

        initializeRoles();
        setIsLoading(false);
    }, [user, actionType]);

    const handleRoleSelection = (role) => {
        if ((actionType === 'grant' && initialRoles.includes(role)) ||
            (actionType === 'revoke' && !initialRoles.includes(role))) {
            return;
        }
        setSelectedRoles(prevRoles => prevRoles.includes(role)
            ? prevRoles.filter(r => r !== role)
            : [...prevRoles, role]
        );
    };

    const isCheckboxDisabled = (role) => {
        return (actionType === 'grant' && initialRoles.includes(role)) ||
            (actionType === 'revoke' && !initialRoles.includes(role));
    };

    const handleInviteWithRoles = async () => {

        setIsLoading(true);
        let allRequestsSuccessful = true;
        try {
            const rolesToProcess = actionType === 'grant'
                ? selectedRoles.filter(role => !initialRoles.includes(role))
                : selectedRoles;
            let updatedRoles = [...initialRoles];

            for (const roleId of rolesToProcess) {
                const data = {
                    [actionType === 'grant' ? 'grantee' : 'revokee']: user.reference,
                    realm: clientId,
                    role: roleId,
                };

                const grantOrRevokeUrl = `/login/v1/${actionType}`;

                const response = await axios.post(
                    `${config.API_URL}${grantOrRevokeUrl}`,
                    data,
                    {headers: authHeaders}
                );
                if (response.data.error && response.data.error.code === "PermissionDenied") {
                    sessionStorage.clear();
                    window.location.href = '/permission-denied';
                    return;
                }


                if (response.status === 200 && Object.keys(response.data).length === 0) {
                    if (actionType === 'grant' && !updatedRoles.includes(roleId)) {
                        updatedRoles.push(roleId);
                    } else if (actionType === 'revoke') {
                        updatedRoles = updatedRoles.filter(role => role !== roleId);
                    }
                } else {
                    allRequestsSuccessful = false;
                    break;
                }
            }


            if (shouldUpdateSessionAndContext) {
                if(allRequestsSuccessful){
                    const newRoles = JSON.stringify(updatedRoles);
                    sessionStorage.setItem('userRoles', newRoles);
                    setRolesDynamically(updatedRoles);
                }
            }
            setTimeout(() => {
                setIsLoading(false);
                if (allRequestsSuccessful) {
                    setSelectedRoles(updatedRoles);
                    onClose();
                }
            }, 1000);
        } catch (error) {
            console.log(error)
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            }
            console.error('Error processing role changes:', error);
        }
    };

    const renderCheckbox = (role) => {
        const isChecked = selectedRoles.includes(role);
        const isDisabled = isCheckboxDisabled(role);

        const checkedIcon = <IndeterminateCheckBox style={{color: 'red'}}/>;
        const icon = <CheckBoxOutlineBlank/>;

        return (
            <Checkbox
                checked={isChecked}
                onChange={() => handleRoleSelection(role)}
                disabled={isDisabled}
                icon={icon}
                checkedIcon={actionType === 'revoke' ? checkedIcon : undefined}
            />
        );
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{fontWeight: 'bold', fontSize: '1.05rem'}}>
                {actionType === 'grant' ? `Select Roles to Add for ${user.username}` : `Select Roles to Revoke for ${user.username}`}
            </DialogTitle>
            {isLoading ? (
                <DialogContent style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <DialogContent>
                    {availableRoles.map(role => (
                        <FormControlLabel
                            key={role}
                            control={renderCheckbox(role)}
                            label={role.charAt(0).toUpperCase() + role.slice(1).replace('_', ' ')}
                        />
                    ))}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInviteWithRoles}
                        fullWidth
                        sx={{mt:2}}
                    >
                        {actionType === 'grant' ? `Grant Selected Roles` : `Revoke Selected Roles`}
                    </Button>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default RoleSelectionModal;