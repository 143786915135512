import React, {useState} from 'react';
import {Box, Button, Step, StepLabel, Stepper, Tooltip} from '@mui/material';
import BeneficiaryStepper from '../ui-component/PaymentStepper/BeneficiaryStepper';
import MainCard from '../ui-component/cards/MainCard';
import CreateUnknownCurrencyCredit from './CreditRequest/CreateUnknownCurrencyCredit';
import CurrencyConverter from "../ui-component/PaymentStepper/CurrencyConveter";
import {AccountCircle, CheckCircle, MonetizationOn} from "@mui/icons-material";
import {styled} from "@mui/system";


const StepIconWrapper = styled('div')(({theme, active}) => ({
    '.MuiSvgIcon-root': {
        fontSize: '2rem',
        transition: 'color 0.3s',
        color: active ? theme.palette.primary.main : theme.palette.action.disabled,
    }
}));

// ============================|| Make  - Payment ||============================ //
const MakePayment = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [fxDealDetails, setFxDealDetails] = useState({});

    const handleBeneficiarySelection = (beneficiaryValue) => {
        setSelectedBeneficiary(beneficiaryValue);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const StepIcons = [
        {icon: <AccountCircle/>, label: "Choose Your Beneficiary"},
        {icon: <MonetizationOn/>, label: "Create Transaction"},
        {icon: <CheckCircle/>, label: "Confirmation"},
    ];
    return (
        <Box mt={2}>
            <MainCard content>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {StepIcons.map((step, index) => (
                        <Step key={index}>
                            <StepLabel
                                icon={
                                    <Tooltip title={step.label} placement="bottom">
                                        <StepIconWrapper active={activeStep === index}>
                                            {step.icon}
                                        </StepIconWrapper>
                                    </Tooltip>
                                }
                            >
                                {''} {/* Hide default step number */}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 0 && (
                    <BeneficiaryStepper handleNext={handleNext}
                                        handleBeneficiarySelection={handleBeneficiarySelection}/>
                )}

                {activeStep === 1 && (
                    <Box>
                        <CurrencyConverter handleBack={handleBack} handleNext={handleNext}
                                           EntityId={selectedBeneficiary}
                                           onApiResponse={(apiResponse) => setFxDealDetails(apiResponse)}/>
                    </Box>
                )}

                {activeStep === 2 && (
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                        <CreateUnknownCurrencyCredit
                            EntityId={selectedBeneficiary}
                            onBack={handleBack}
                            fxDealDetails={fxDealDetails}
                        />
                        <Button
                            onClick={() => setActiveStep(0)}
                            variant="contained"
                            color="warning"
                            sx={{px: 4.5}}
                        >
                            Cancel
                        </Button>
                    </Box>
                )}
            </MainCard>
        </Box>
    );
};

export default MakePayment;
