// Client Account Context
import {createContext, useContext, useState} from "react";

const ClientAccountContext = createContext();

export const useClientAccount = () => useContext(ClientAccountContext);

export const ClientAccountProvider = ({ children }) => {
    const [clientId, setClientId] = useState(sessionStorage.getItem('clientId') || '');

    const updateClient = (newClientId) => {
        setClientId(newClientId);
        sessionStorage.setItem('clientId', newClientId);
    };

    return (
        <ClientAccountContext.Provider value={{ clientId, updateClient }}>
            {children}
        </ClientAccountContext.Provider>
    );
};