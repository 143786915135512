import React, {useContext, useEffect, useState} from 'react';

// materialUI imports
import {Box} from '@mui/material';

// project imports
import {useAuthHeaders} from '../AuthHeaders';
import ConfigContext from "../../store/Context/ConfigContext";
import CreditResponse from "../response/CreditResponse";
import CreditForm from "./CreditForm";
import {formattedDate} from "../../store/getDate";
import useEntityDetails from "../../store/Hooks/useEntityDetails";

// 3rd Party imports
import * as Yup from 'yup';
import {Formik} from 'formik';
import useSubmitCredit from "../../store/Hooks/useSubmitCredit";

export const usdResponse = {
    "id": 1,
    "rate": "1",
    "expiry": "2024-09-19T16:53:36.916Z",
    "inverse": "1",
    "currency": "USD"
};
// ============================|| Create - Credit ||============================ //

const CreateUSDCredit = ({EntityId, entity}) => {
    const responseRef = React.useRef(null);
    const [paymentType] = useState("Domestic");
    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [confirmationData, setConfirmationData] = useState("");
    const {entityDetails} = useEntityDetails(EntityId);

    const {
        handleSubmit: handleCreditSubmit,
        response: creditResponse

    } = useSubmitCredit(authHeaders, config, paymentType);
    const handleConfirmationDataChange = (data) => {
        setConfirmationData(data)
    }
    useEffect(() => {
        if (creditResponse) {
            setResponseModalOpen(true);
        }
    }, [creditResponse]);

    const clearCreditResponse = () => {
        setResponseModalOpen(false);
        setConfirmationData(null);
    };
    return (
        <Box mt={2}>
            <Formik
                initialValues={{
                    reference: `${EntityId} - ${formattedDate}`,
                    account: undefined,
                    amount: '',
                    currency: 'USD',
                    entityId: EntityId || (entity ? entity.reference : ''),
                    when: 'standard',
                    description: '',
                    totp: "",
                }}
                validationSchema={Yup.object().shape({
                    reference: Yup.string().required('Internal Tracking Reference is required'),
                    account: Yup.string(),
                    amount: Yup.number().required('Amount is required'),
                    currency: Yup.string().required('Currency is required'),
                    entityId: Yup.string().required('Entity Reference is required'),
                    when: Yup.string().required('When is required'),
                    description: Yup.string()
                        .required('Description is required')
                        .max(35, 'Description must be a maximum of 35 characters'),
                })}

                onSubmit={handleCreditSubmit}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      touched,
                      errors,
                      setSubmitting,
                      validateForm,
                      setTouched,
                  }) => (

                    <CreditForm
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        validateForm={validateForm}
                        setTouched={setTouched}
                        requestData={usdResponse}
                        onConfirmationDataChange={handleConfirmationDataChange}
                        handleSubmit={handleSubmit}
                        setSubmitting={setSubmitting}
                        entityDetails={entityDetails}
                    />

                )}
            </Formik>

            {creditResponse && (
                <Box mt={2} ref={responseRef}>
                    <CreditResponse
                        response={creditResponse}
                        open={responseModalOpen}
                        clearResponse={clearCreditResponse}/>
                </Box>
            )}
        </Box>

    );
};

export default CreateUSDCredit;
