import React from "react";
import getFormattedCurrencyAmount from "../../store/getFormattedCurrencyAmount";
import TransactionStatusChip from "../extended/TransactionStatusChip";
import {formatDateToMonthDayYear} from "../../store/getDate";


const dateComparator = (date1, date2) => {
    return new Date(date1) - new Date(date2);
};

export const transactionHistoryColumns = [
    {field: 'type', headerName: 'Type', flex: .5, hide: true, },
    {field: 'reference', headerName: 'Reference', flex: 1},
    {
        field: 'sendAmountWithCurrency',
        headerName: 'Amount',
        flex: 0.75,
        sortable: true,
        valueGetter: (params) => `${getFormattedCurrencyAmount(params.row.amount, params.row.currency)}`
    },
    {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 0.75,
        sortable: true,
        sortComparator: dateComparator,
        valueGetter: (params) => {
            const isoDateString = params.row.createdAt;
            if (!isoDateString) return '';
            const dateObject = new Date(isoDateString);
            const formattedDate = formatDateToMonthDayYear(dateObject);
            return formattedDate || '';
        }
    },
    {
        field: 'state',
        headerName: 'Status',
        flex: 0.25,
        headerAlign: 'right',
        align: 'right',
        sortable: true,
        renderCell: (params) => (
            <TransactionStatusChip state={params.value}/>
        ),
    },
];