import React, {useContext, useState} from "react";

// materialUI imports
import {Box, Button, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";

// project imports
import CreateCredit from "../CreditRequest/CreateCredit";
import {AuthContext} from "../../auth/AuthContext";
import currencyFlagHelper from "store/currencyFlagHelper";
import {getCurrencySymbol} from "../../store/getFormattedCurrencyAmount";
import {RolesContext} from "../../store/Context/RolesContext";

// ============================|| FX QUOTE - RESPONSE - DISPLAY ||============================ //


const FxQuoteResponseDisplay = ({response, request}) => {
    const {expiry, id, rate, inverse, currency} = response;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {isAuthenticated} = useContext(AuthContext);
    const {roles} = useContext(RolesContext)

    const handleOpenModal = () => {
        if (isAuthenticated) {
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const readableExpiry = new Date(expiry).toLocaleString("en-US", {
        day: "numeric", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit", timeZoneName: "short"
    });

    const renderCurrencyPair = (fromCurrency, toCurrency, rate) => {
        const fromFlag = currencyFlagHelper(fromCurrency)
        const toFlag = currencyFlagHelper(toCurrency)

        return (<TableRow key={`${fromCurrency}-${toCurrency}`}>
            <TableCell>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    gap='8px'
                >

                    <Typography style={{
                        display: 'flex', alignItems: 'center'
                    }}>
                        {fromFlag && fromFlag}
                    </Typography>
                    <Typography variant="h3">{fromCurrency} {getCurrencySymbol(fromCurrency)}</Typography>
                    <Typography>➡️</Typography>
                    <Typography style={{
                        display: 'flex', alignItems: 'center'
                    }}>
                        {toFlag && toFlag}
                    </Typography>
                    <Typography variant="h3">{toCurrency} {getCurrencySymbol(toCurrency)}</Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Typography variant="h3">
                    <strong>{rate}</strong>
                </Typography>
            </TableCell>
        </TableRow>);
    };

    if (response.error) {
        return (<Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="75%"
        >
            <Typography variant="h4" color="error">
                {`Error: ${response.error.code} - ${response.error.message}`}
            </Typography>
        </Box>);
    }

    return (<Box>
            <Typography variant="h2" gutterBottom>
                🔄 Currency Exchange Rates
            </Typography>
            <Typography variant="h4" gutterBottom>
                🕒 Expiry: {readableExpiry}
            </Typography>

            <Box mt={2} sx={{backgroundColor: "#B0C0C8", borderRadius: "8px", overflow: "hidden"}}>
                <Table>
                    <TableHead>
                        <TableRow sx={{backgroundColor: "#005681"}}>
                            <TableCell>
                                <Typography variant="h3" color={"white"}>
                                    Currency Pair
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h3" color={"white"}>
                                    Rate
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderCurrencyPair("USD", currency, rate)}
                        {renderCurrencyPair(currency, "USD", inverse)}
                    </TableBody>
                </Table>
            </Box>
            {/*{roles.includes("payment_create") && (*/}
            {/*    <Button onClick={handleOpenModal} variant={'contained'}*/}
            {/*            sx={{backgroundColor: '#012169', color: "#fff", mt: 1}}>*/}
            {/*        Proceed to Book*/}
            {/*    </Button>*/}
            {/*)}*/}

            <>
                <Modal open={isModalOpen} onClose={handleCloseModal}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 'fit-content',
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        bgcolor: 'white',
                        borderRadius: '16px',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                        p: 4,
                        outline: 'none',
                    }}>
                        <Typography variant={'h2'}> Book Transaction </Typography>
                        <CreateCredit
                            FxQuoteId={id}
                            EntityId={request.request.entityId}
                            Currency={response.currency}
                            Rate={response.rate}
                            Response={response}
                        />
                    </Box>
                </Modal>
            </>
        </Box>

    );
};

export default FxQuoteResponseDisplay;