import React from 'react';
import MainCard from "../ui-component/cards/MainCard";
import InviteForm from "../requests/InviteForm";
import {Grid, Typography} from "@mui/material";

// ============================|| USER TABLE DISPLAY ||============================ //
const Invite = () => {
    const clientAccount = sessionStorage.getItem('clientName')
    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h3">Invite a user to {clientAccount}</Typography>
                    </Grid>
                </Grid>
            }
            content={true}
        >
            <InviteForm/>
        </MainCard>
    );
};

export default Invite;