// Project imports
import makeArrayBuffer from './makeArrayBuffer'
import makePDFLibDocument from './makePDFLibDocument'
import updatePDFLibDocument from './updatePDFLibDocument'
import visab2bTemplateOptions from './templates/visab2bTemplateOptions'

// ============================|| PDF GENERATOR ||============================ //

const pdfGeneratorHandler = async (templateData, pdfTemplate, fileNamePrefix) => {
    // 1 - fetch pdf and convert to arraybuffer
    const arrayBuffer = await makeArrayBuffer(pdfTemplate)
    // 2 - use arrayBuffer to create a PDF-lib object
    let pdfLibDoc = await makePDFLibDocument(arrayBuffer)
    // 3 - update the pdf-lib document with templateData and return an array buffer
    pdfLibDoc =  await updatePDFLibDocument(pdfLibDoc, templateData)

    return { arrayBuffer: pdfLibDoc, fileNamePrefix }
}

const pdfGenerator = (data) => {
    const templateName = sessionStorage.getItem('pdfTemplate');

    const transactionData = data.credit;
    const details = transactionData.receiver.details
    // Determine what PDF Template to use:
    let detailType

    if (details.ach) {
        detailType = "ach";

    } else if (details.visab2b) {
        detailType = "visab2b";
    }

    switch (detailType) {

        case "visab2b":
            const { templateData, pdfTemplate, fileNamePrefix } = visab2bTemplateOptions(transactionData, templateName)
            return pdfGeneratorHandler(templateData, pdfTemplate, fileNamePrefix)
    }
}

export default pdfGenerator