import React from 'react';
import CustomFormField from "../CustomFormField";
import CustomDropdown from "../CustomDropdown";
import * as Yup from "yup";
import {useCountryRegions} from "../../store/Hooks/useCountryRegions";
import {ToggleButton, ToggleButtonGroup, Grid} from "@mui/material";

const branchNameCountries = ['EG', 'KE', 'MN', 'PY', 'TZ', 'UY', 'VN'];

// ============================|| Form Data - Validation Schemas ||============================ //
export const ACHSchema = Yup.object().shape({
    InfoType: Yup.string().required('Network Type is required'),
    type: Yup.string().required('Entity Type is required'),
    entityId: Yup.string().required('Entity Reference is required'),
    name: Yup.string().required('Name is required'),
    achType: Yup.string().required('ACH Type is required'),
    aba: Yup.string()
        .required('ABA is required')
        .length(9, 'ABA must be exactly 9 digits')
        .matches(/^\d+$/, 'ABA must contain only digits'),
    account: Yup.string().required('Account is required'),
    description: Yup.string().required('Description is required'),
});


// ============================|| VisaB2B - Validation ||============================ //
const isValidBIC = (bic) => {
    const bicRegex = /^[0-9A-Z]{4}[A-Z]{2}[0-9A-Z]{2}([0-9A-Z]{3})?$/;
    return bicRegex.test(bic.toUpperCase());

};
const ibanRegex = /[A-Z]{2}[0-9]{2}[0-9A-Z]{11,30}/


export const VisaB2BSchema = Yup.object().shape({
    InfoType: Yup.string().required('Network Type is required'),
    type: Yup.string().required('Beneficiary Type is required'),
    entityId: Yup.string().required('Entity Reference is required'),
    name: Yup.string().required('Name is required'),
    bic: Yup.string()
        .required('BIC or Swift is required')
        .test('isValidBIC', 'Invalid BIC format', value => isValidBIC(value)),
    paymentType: Yup.string().required('Payment Type is required'),
    street1: Yup.string().required('Street Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    postalCode: Yup.string().required('Zip Code/ Postal Code is required'),
    description: Yup.string().required('Description is required'),
    iban: Yup.string()
        .notRequired()
        .matches(ibanRegex, 'Invalid IBAN format'),
    account: Yup.string().notRequired(),
    branch: Yup.string().when('country', (countryArray, schema) => {
        const country = countryArray && countryArray.length > 0 ? countryArray[0] : '';
        return branchNameCountries.includes(country)
            ? schema.required('Branch Name is required')
            : schema.notRequired();
    }),
   });
// ============================|| InterLedger - Validation ||============================ //
export const InterLedgerSchema = Yup.object().shape({
    InfoType: Yup.string().required('Network Type is required'),
    type: Yup.string().required('Entity Type is required'),
    entityId: Yup.string().required('Entity Reference is required'),
    name: Yup.string().required('Name is required'),
    paymentPointer: Yup.string().required('Payment Pointer is required'),
    street1: Yup.string().required('Street Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    postalCode: Yup.string().required('Zip Code/ Postal Code is required'),
    description: Yup.string().required('Description is required'),
});
// ============================|| Default - Validation ||============================ //

export const DefaultSchema = Yup.object().shape({
    InfoType: Yup.string().required('Network Type is required'),
});

// ============================|| Form Data - Create Beneficiary ||============================ //


export const VisaB2BForm = ({values, handleChange, handleBlur, touched, errors}) => {
    const {countries, regions, handleCountryChange} = useCountryRegions(handleChange);
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={4}>
                <CustomFormField
                    name="bic"
                    label="BIC / Swift"
                    value={values.bic}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>

            <Grid item xs={6} lg={4}>
                <Grid container alignItems="center" justifyContent="center" style={{height: '100%'}}>
                    <ToggleButtonGroup
                        exclusive
                        name="paymentType"
                        value={values.paymentType}
                        onChange={(_, newValue) => handleChange({target: {name: 'paymentType', value: newValue}})}
                        aria-label="Payment Type"
                    >
                        <ToggleButton value="iban" aria-label="IBAN">
                            IBAN
                        </ToggleButton>
                        <ToggleButton value="account" aria-label="Account">
                            Account
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <Grid item xs={6} lg={4}>
                {values.paymentType === 'iban' && (
                    <CustomFormField
                        name="iban"
                        label="Enter IBAN"
                        value={values.iban}
                        handleChange={(event) => {
                            if (event.target.name === 'iban') {
                                event.target.value = event.target.value.replace(/\s+/g, '');
                            }
                            handleChange(event);
                        }}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                )
                }
                {values.paymentType === 'account' && (
                    <CustomFormField
                        name="account"
                        label="Enter Account"
                        value={values.account}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                )}
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomDropdown
                    name="country"
                    label="Country"
                    value={values.country}
                    handleChange={handleCountryChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={countries}
                    maxHeight={"50vh"}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {values.country && (
                    <CustomDropdown
                        name="state"
                        label="Region/State"
                        value={values.state}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={regions}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <CustomFormField
                    name="street1"
                    label="Street Address"
                    value={values.street1}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    validateChars={true}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="city"
                    label="City"
                    value={values.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="postalCode"
                    label="Zip Code / Postal Code"
                    value={values.postalCode}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    validateChars={true}
                />
            </Grid>
            {branchNameCountries.includes(values.country) && (
                <Grid item xs={12}>
                    <CustomFormField
                        name="branch"
                        label="Branch Name"
                        value={values.branch}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        validateChars={true}
                    />
                </Grid>
                )}
        </Grid>
    );
};


// ============================|| Interledger - Form ||============================ //
export const InterLedgerForm = ({values, handleChange, handleBlur, touched, errors}) => {
    const {countries, regions, handleCountryChange} = useCountryRegions(handleChange);


    return (<Grid container spacing={1}>
        <Grid item xs={12} md={5}>
            <CustomFormField
                name="paymentPointer"
                label="Payment Pointer"
                value={values.paymentPointer}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
            />
        </Grid>

        <Grid item xs={12}>
            <CustomFormField
                name="street1"
                label="Street Address"
                value={values.street1}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
            />
        </Grid>

        <Grid item xs={12} md={6}>
            <CustomDropdown
                name="country"
                label="Country"
                value={values.country}
                handleChange={handleCountryChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                options={countries}
                maxHeight={"50vh"}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            {values.country && (
                <CustomDropdown
                    name="state"
                    label="Region/State"
                    value={values.state}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={regions}
                />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
            <CustomFormField
                name="city"
                label="City"
                value={values.city}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <CustomFormField
                name="postalCode"
                label="Zip Code / Postal Code"
                value={values.postalCode}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
            />
        </Grid>
    </Grid>);
};


// ============================|| Ach - Form ||============================ //

export const ACHForm = ({values, handleChange, handleBlur, touched, errors}) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <CustomDropdown
                    name="achType"
                    label="ACH Type"
                    value={values.achType}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={[
                        {value: 'dda', label: 'Demand Deposit Account'},
                        {value: 'savings', label: 'Savings'},
                    ]}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="aba"
                    label="ABA"
                    value={values.aba}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="account"
                    label="Account"
                    value={values.account}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
        </Grid>
    );
};