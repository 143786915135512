import React from 'react';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, Button} from "@mui/material";
import {useTheme} from "@mui/material/styles";

// ==============================|| CUSTOM FILTER DATA GRID ||============================== //
function QuickSearchToolbar() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                pb: 1,
                pl: 3,
                pr: 2,
                pt: 2,
                '& .MuiFormControl-root > .MuiInputBase-root': {
                    p: 0.6,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: 2,
                    background: theme.palette.grey[50]
                },
                '& .MuiFormControl-root > .MuiInputBase-root:after': {
                    display: 'none'
                },
                '& .MuiFormControl-root > .MuiInputBase-root:before': {
                    display: 'none'
                },
                '& .MuiFormControl-root > .Mui-focused': {
                    border: `1px solid ${theme.palette.primary.main}`
                },
                '& .MuiSvgIcon-root': {
                    color: `${theme.palette.primary.main} !important`,
                },

            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    );
}




const RefreshQueryToolbar = ({onRefresh}) => {
    return (
        <GridToolbarContainer sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent:"center"}}>
                <GridToolbarColumnsButton color="secondary"/>
                <GridToolbarExport color="secondary"/>
                <Button
                    onClick={onRefresh}
                    size="small"
                    startIcon={<RefreshIcon/>}
                    color="secondary"
                    sx={{marginLeft: '8px', textTransform: 'none'}}
                >
                    Refresh
                </Button>
            </Box>
            <QuickSearchToolbar/>
        </GridToolbarContainer>
    );
}

export default RefreshQueryToolbar;