import React, {useContext, useEffect, useState} from "react";
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {AuthContext} from "../../auth/AuthContext";
import CircularSpinner from "../../ui-component/CircularSpinner";
import {useBeneficiaries} from "../../store/Context/EntityContext";
import {useClientAccount} from "../../store/Context/ClientAccountContext";
import {RolesContext} from "../../store/Context/RolesContext";


const ClientAccountDropDown = () => {
    const [loading, setLoading] = useState(false);
    const realmsData = JSON.parse(sessionStorage.getItem('realms')) || [];
    const { setIsAuthenticated } = useContext(AuthContext);
    const { clientId, updateClient } = useClientAccount();
    const { triggerReload } = useBeneficiaries();
    const { setRolesDynamically } = useContext(RolesContext);


    const setRolesForClient = (selectedRealm) => {
        const roleNames = selectedRealm.roles.map((role) => role.name);
        sessionStorage.setItem('userRoles', JSON.stringify(roleNames));
        setRolesDynamically(roleNames);

    };

    useEffect(() => {
        const delayExecution = setTimeout(() => {
            const storedClientId = sessionStorage.getItem('clientId');
            const initialClientAccount = storedClientId || (realmsData.length === 1 ? realmsData[0].id : "");
            updateClient(initialClientAccount);
            sessionStorage.setItem('clientId', initialClientAccount);
            if (realmsData.length === 1) {
                sessionStorage.setItem('clientName', realmsData[0].name);
                setRolesForClient(realmsData[0])
            }
        },
            1500);

        return () => clearTimeout(delayExecution);
    }, [realmsData]);

    const handleSelectChange = async (event) => {
        const selectedRealmId = event.target.value;
        const selectedRealm = realmsData.find((realm) => realm.id === selectedRealmId);

        if (selectedRealm) {
            setIsAuthenticated(true);
            setLoading(true);
            updateClient(selectedRealm.id);
            sessionStorage.setItem('clientName', selectedRealm.name);
            setRolesForClient(selectedRealm);
            await new Promise((resolve) => setTimeout(resolve, 1000));  // This just waits for 1 second
            setLoading(false);
            triggerReload();
        }
    };

    realmsData.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            {loading ? (
                <Box sx={{ mr: 5 }}>
                    <CircularSpinner />
                </Box>
            ) : realmsData.length === 1 ? (
                <Typography sx={{mr:2}}>Welcome, {realmsData[0].name}</Typography>
            ) : (
                <FormControl
                    variant="outlined"
                    sx={{
                        width: "240px",
                        mr: 3,
                    }}
                >
                    <InputLabel id="clientAccount-label">Client Account</InputLabel>
                    <Select
                        labelId="clientAccount-label"
                        id="clientAccount"
                        name="clientAccount"
                        label="Client Account"
                        value={clientId}
                        onChange={handleSelectChange}
                    >
                        {realmsData.map((realm, index) => (
                            <MenuItem key={index} value={realm.id}>
                                {realm.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
};

export default ClientAccountDropDown