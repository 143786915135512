// Project imports

// 3rd Party imports
import currency from 'currency.js'
import getSymbolFromCurrency from 'currency-symbol-map'


// ==============================|| GET CURRENCY SYMBOL ||============================== //
export const getCurrencySymbol = (currencyValue) => {
    return getSymbolFromCurrency(currencyValue) || ""
};


// ============================|| FORMATTED CURRENCY ||============================ //

const getFormattedCurrencyAmount = (amount, currencyValue) => {

    const formattedAmount = currency(amount, { symbol: getCurrencySymbol(currencyValue) }).format()  
    return `${formattedAmount} ${currencyValue}`
}

export default getFormattedCurrencyAmount