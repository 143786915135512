import React, {useState} from 'react';
import {Box, IconButton, Modal, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {ResponseDisplay} from "../../requests/response/ResponseDisplay";
import CreateEntityForm from "../../requests/CreateEntity";

const CreateEntityModal = ({ open, handleClose }) => {
    const [response, setResponse] = useState(null);
    const handleCloseModal = () => {
        setResponse(null);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '95vw', md: 'auto' },
                    maxWidth: '95vw',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    borderRadius: 8,
                    p: 4,
                }}
            >
                {/* Header Box */}
                <Box sx={{marginBottom: '15px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography sx={{
                            fontSize: '1.5em',
                            fontWeight: 'bold',
                            color: '#333',
                        }}>
                            Create Beneficiary
                        </Typography>
                        <IconButton onClick={handleCloseModal} aria-label="close" size="small">
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </Box>

                {/* Body of the Modal */}
                <Box sx={{ overflow: 'auto' }}>
                    <CreateEntityForm/>
                </Box>

                {/* Response Display */}
                {response && (
                    <Box sx={{ mt: 2 }}>
                        <ResponseDisplay response={response} typeOfRequest={"Beneficiary Creation"}/>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default CreateEntityModal;
