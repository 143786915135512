import React, {useState} from "react";

// materialUI imports
import {Box, Button, Dialog, DialogActions, DialogContent, Fade, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import TransactionSummary from "ui-component/TransactionSummary";

// 3rd Party Imports
import {Link} from "react-router-dom";


// ============================|| RESPONSE DISPLAY ||============================ //

export const ResponseDisplay = ({response, selectedTransaction, typeOfRequest}) => {

    const [isModalOpen, setModalOpen] = useState(false);

    const theme = useTheme();

    if (!response || response.error) {
        let errorMessage = response?.error?.message || null;
        if (response?.error?.code === "InvalidTOTP") {
            errorMessage = "Hmm, something seems to be wrong with the code you entered. Please try again.";
        }

        return (
            <>
                <Fade in={!!response?.error}>
                    <Typography variant="h4" sx={{color: theme.palette.error.main}}>
                        Request Error
                    </Typography>
                </Fade>
                <Typography sx={{mt: 1}} variant="h6">
                    {errorMessage}
                </Typography>

                {response?.error?.transactionSummaryExists && (
                    <>
                        <Dialog
                            open={isModalOpen}
                            onClose={() => setModalOpen(false)}
                            fullWidth
                            maxWidth={"md"}
                        >
                            <DialogContent>
                                <TransactionSummary selectedTransaction={selectedTransaction}/>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={() => setModalOpen(false)} color="secondary" variant={"contained"}>
                                    Close
                                </Button>
                            </DialogActions>

                        </Dialog>

                        <Typography sx={{mt: 1}} variant="h6">
                            View your archived summary <Link onClick={() => setModalOpen(true)}>here</Link>.
                        </Typography>

                    </>
                )}
            </>
        );
    }

    const renderProperty = (property, value) => {
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                // If the value is an array, render its items
                return (
                    <Box key={property}>
                        <Typography variant="body1">
                            <strong>{property}:</strong>
                        </Typography>
                        {value.map((item, index) => (
                            <Typography variant="body1" key={index}>
                                {item}
                            </Typography>
                        ))}
                    </Box>
                );
            } else {
                // If the value is an object, recursively render its properties
                return (
                    <Box key={property}>
                        <Typography variant="body2">
                            <strong>{property}</strong>
                        </Typography>
                        {Object.entries(value).map(([nestedProp, nestedValue]) =>
                            renderProperty(nestedProp, nestedValue)
                        )}
                    </Box>
                );
            }
        }

        // If the value is not an object or array, render the property and its value
        return (
            <Typography variant="body1" key={property}>
                <strong>{property}:</strong> {value}
            </Typography>
        );
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                {typeOfRequest} Complete:
            </Typography>
            {typeof response === 'object' && true
                ? Object.entries(response).map(([property, value]) => renderProperty(property, value))
                : null}
        </Box>
    );
};