import React, {useEffect, useState} from "react";

// materialUI imports
import {Button, Grid, IconButton, Typography} from "@mui/material";

// project imports
import {InfoText} from "../../ui-component/extended/InfoText";
import CustomFormField from "../../ui-component/CustomFormField";
import CustomCurrencyValueInput from "../../ui-component/CustomCurrencyValueInput";
import CustomDropdown from "../../ui-component/CustomDropdown";
import ConfirmationModal from "../../ui-component/extended/ConfirmationModal";
import {getCurrencyFlag} from "../../store/currencyFlagHelper";
import {getCurrencySymbol} from "../../store/getFormattedCurrencyAmount";

// 3rd Party imports
import {Form, useFormikContext} from "formik";
import * as PropTypes from "prop-types";
import {IconReplace} from "@tabler/icons";
import currency from "currency.js";

// ============================|| CREDIT FORM ||============================ //


const CreditForm = ({
                        values,
                        handleChange,
                        handleBlur,
                        touched,
                        errors,
                        validateForm,
                        setTouched,
                        requestData,
                        fxCredit,
                        fxDealDetails,
                        onConfirmationDataChange,
                        handleSubmit,
                        entityDetails
                    }) => {
    const [showInfo, setShowInfo] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationData, setConfirmationData] = useState("");
    const [displayedCurrency, setDisplayedCurrency] = useState(
        requestData.currency ? requestData.currency : 'USD');
    const [totpValue, setTotpValue] = useState();

    const handleTOTPChange = (totp) => {
        setTotpValue(totp);
    };

    const handleConfirmationOpen = (values) => {
        setConfirmationData(values);
        setConfirmationModalOpen(true);
        onConfirmationDataChange(values);
    };

    const handleConfirmationClose = () => {
        setConfirmationModalOpen(false);
    };


    return <Form style={{width: '100%', maxWidth: '800px'}}>
        <div
            onClick={() => setShowInfo(prev => !prev)}
            style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "5px"
            }}
        >
            <Typography variant="h6" component="h2" gutterBottom style={{marginRight: "8px"}}>
                Need Help?
            </Typography>
        </div>
        <Grid container spacing={1}>
            <Grid item xs={8}>
                <InfoText
                    text={"A unique reference for this request. This is used to ensure\n" +
                        "idempotency. If a request is received with a duplicate\n" +
                        "reference, it will not be applied twice; you will receive a\n" +
                        "success response and the latest state."}
                    showInfo={showInfo}
                />
                <CustomFormField
                    name="reference"
                    label="Internal Tracking Reference"
                    value={values.reference}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    validateChars={true}
                />
            </Grid>
            <Grid item xs={12} sm={8}>
                <InfoText
                    text={"The Entity Reference of the payee. Funds will be transferred from the\n" +
                        "payer (the client) to the payee."}
                    showInfo={showInfo}/>
                <CustomFormField
                    name="entityId"
                    label="Beneficiary"
                    value={values.entityId}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <CustomCurrencyValueInput
                    name="amount"
                    label="Amount"
                    value={values.amount}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    disabled={fxDealDetails}
                />

            </Grid>
            <Grid item xs={12} sm={5}>
                <CustomDropdown
                    name="currency"
                    label="Currency"
                    value={displayedCurrency}
                    handleChange={(e) => {
                        setDisplayedCurrency(e.target.value);
                        handleChange(e);
                    }}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    disabled={fxDealDetails}

                    options={[
                        { value: "USD", label: "USD", icon: getCurrencyFlag('USD') },
                        ...(fxCredit || fxDealDetails) ? [{
                            value: requestData?.currency || fxDealDetails?.currency || 'USD',
                            label: requestData?.currency || fxDealDetails?.currency || 'USD',
                            icon: getCurrencyFlag(requestData?.currency || fxDealDetails?.currency || 'USD')
                        }] : []
                    ]}
                />
            </Grid>
            {fxCredit && values.amount > 0.01 &&
                <Grid item xs={10}>
                    <Typography variant="body1" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '8px',
                        backgroundColor: '#f4f4f4',
                        borderRadius: '8px',
                        padding: '8px',
                        color: '#012169',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}>
                        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                            <span>Total in</span>
                            <span style={{
                                color: '#1E88E5',
                                textTransform: 'uppercase',
                                fontWeight: 'bold'
                            }}>
                                          {requestData.currency !== displayedCurrency ? requestData.currency : 'USD'}
                                        </span>
                            <span>:</span>
                            <span style={{fontWeight: 'bold', color: '#1E88E5',}}>
                                {displayedCurrency === requestData.currency
                                    ? currency(values.amount, {symbol: getCurrencySymbol('USD')}).multiply(requestData.inverse).format()
                                    : currency(values.amount, {symbol: getCurrencySymbol(requestData.currency)}).multiply(requestData.rate).format()}
                            </span>
                        </div>
                        <IconButton
                            onClick={() =>
                                setDisplayedCurrency(displayedCurrency === 'USD' ? requestData.currency : 'USD')
                            }
                            color="primary"
                            size="small"
                            sx={{
                                backgroundColor: '#00B5E2',
                                color: '#FFF',
                                '&:hover': {backgroundColor: '#00A1D6'}
                            }}
                        >
                            <IconReplace sx={{fontSize: '1rem'}}/>
                        </IconButton>
                    </Typography>
                </Grid>
            }
            <Grid item xs={8}>
                <CustomFormField
                    name="description"
                    label="Description"
                    value={values.description}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    maxLength={35}
                    validateChars={true}
                />
            </Grid>
        </Grid>
        <Button
            variant={"contained"}
            color={"secondary"}
            onClick={async () => {
                const errors = await validateForm();
                console.log(errors);
                if (Object.keys(errors).length > 0) {
                    setTouched({
                        reference: true,
                        amount: true,
                        description: true,
                    });
                } else {
                    handleConfirmationOpen({ ...values, totpValue });
                 }
            }}
        >
            Confirm Transaction
        </Button>
        {confirmationData && (
            <ConfirmationModal
                open={confirmationModalOpen}
                onCancel={handleConfirmationClose}
                onConfirm={async () => {
                    values.totpValue = totpValue;
                    await handleSubmit(values);
                    handleConfirmationClose();
                }}
                data={confirmationData}
                requestData={requestData}
                displayedCurrency={displayedCurrency}
                entityDetails={entityDetails}
                onTOTPChange={handleTOTPChange}
            />
        )}
    </Form>;
}

CreditForm.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
    value: PropTypes.any,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    requestData: PropTypes.any,
    fxCredit: PropTypes.bool,
    Response: PropTypes.object,
    setDisplayedCurrency: PropTypes.func,
    displayedCurrency: PropTypes.string,
    onConfirmationDataChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
};

export default CreditForm;