import React, {useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import {currencyOptions} from '../CurrencyOptions';
import PropTypes from 'prop-types';
import ConfigContext from '../../store/Context/ConfigContext';
import {useAuthHeaders} from '../../requests/AuthHeaders';
import useFetchRate from "../../store/Hooks/useFetchRate";
import {ExpandMore, Refresh} from "@mui/icons-material";
import {NumericFormat} from "react-number-format";
import CircularSpinner from "../CircularSpinner";
import {RolesContext} from "../../store/Context/RolesContext";
import usdFlag from "../../assets/images/usd_flag.png";

const CurrencyConverter = ({handleBack, handleNext, EntityId, onApiResponse}) => {
    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();
    const clientId = sessionStorage.getItem('clientId');
    const [rateCalculated, setRateCalculated] = useState(false);
    const [showInverseRate, setShowInverseRate] = useState(false);
    const {roles} = useContext(RolesContext);
    const userHasGetQuoteRole = roles.includes('quote');


    const {
        rate,
        isLoading,
        fetchRate,
        submitCurrencyConversion,
        apiResponse,
        error
    } = useFetchRate(config, authHeaders, clientId, EntityId);


    const responseBoxStyle = {
        transition: 'opacity 0.3s ease-in-out',
        opacity: apiResponse ? 1 : 0,
    };

    const selectCurrencyStyle = {
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: '1.2rem',
        '& .MuiSelect-select': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiSelect-icon': {
            color: 'rgba(0, 0, 0, 0.54)',
        },
    };

    const textFieldStyle = {
        mb: 2,
        '& .MuiInputBase-input': {
            fontSize: '1.2rem',
            padding: '20px 18px',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
        },
    };

    const formik = useFormik({
        initialValues: {
            sellAmount: '1000',
            buyAmount: '',
            sendCurrency: 'USD',
            currency: userHasGetQuoteRole ? 'EUR' : 'USD',

        },
        onSubmit: (values) => {
            submitCurrencyConversion(values, handleNext, onApiResponse);
        },
    });

    const calculateOtherAmount = (amount, isSellAmount) => {
        if (!rate || !amount) return '';

        return isSellAmount
            ? (parseFloat(amount) * rate).toFixed(2)
            : (parseFloat(amount) / rate).toFixed(2);
    };

    const handleAmountChange = (fieldValues) => {
        const {name, value} = fieldValues;
        formik.setFieldValue(name, value);

        if (rate) {
            const otherFieldName = name === 'sellAmount' ? 'buyAmount' : 'sellAmount';
            const otherFieldValue = calculateOtherAmount(value, name === 'sellAmount');
            formik.setFieldValue(otherFieldName, otherFieldValue);
        }
    };

    const handleCurrencyChange = (e) => {
        const {name, value} = e.target;
        formik.setFieldValue(name, value);
        setRateCalculated(false);
    };

    useEffect(() => {
        if (rate && formik.values.sellAmount) {
            const recalculatedBuyAmount = calculateOtherAmount(formik.values.sellAmount, true);
            formik.setFieldValue('buyAmount', recalculatedBuyAmount);
        }
    }, [rate]);


    useEffect(() => {
        if (!rateCalculated) {
            const values = formik.values;
            const amountField = values.sellAmount ? 'sellAmount' : 'buyAmount';
            const amount = values[amountField];

            if (amount && values.currency) {
                fetchRate(formik.values.currency, amountField, amount);
                setRateCalculated(true);
            }
        }
    }, [formik.values.currency, formik.values.sellAmount, formik.values.buyAmount, rateCalculated]);

    const refetchRate = () => {
        const values = formik.values;
        const amountField = values.sellAmount ? 'sellAmount' : 'buyAmount';
        const amount = values[amountField];
        if (amount && values.currency) {
            fetchRate(values.currency, amountField, amount);
        }
    };

    return (
        <Container maxWidth="sm" sx={{my: 5}}>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{p: 1, bgcolor: 'background.paper'}}>
                    <Typography variant="body2" sx={{color: 'text.secondary', mb: 1}}>
                        You send exactly
                    </Typography>
                    <NumericFormat
                        fullWidth
                        variant="outlined"
                        name="sellAmount"
                        value={formik.values.sellAmount}
                        onValueChange={(values) => {
                            handleAmountChange({name: "sellAmount", value: values.floatValue})
                        }}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        customInput={TextField}
                        sx={textFieldStyle}
                        disabled={isLoading}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Select
                                        name="sendCurrency"
                                        value={formik.values.sendCurrency}
                                        onChange={handleCurrencyChange}
                                        displayEmpty
                                        sx={selectCurrencyStyle}
                                        disabled={true}
                                    >
                                        {currencyOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={option.icon}
                                                        alt={option.label}
                                                        style={{marginRight: '10px', height: '30px'}}
                                                    />
                                                    {option.label}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {isLoading && (
                        <Box sx={{display: 'flex', justifyContent: 'center', my: 2}}>
                            <CircularSpinner/>
                        </Box>
                    )}

                    {error &&
                        <Box sx={{ p: 1, bgcolor: "background.paper", display: "flex", alignItems: "center" }}>
                            <Typography variant="h4" sx={{ flexGrow: 1, textAlign: "center" }}>
                                Something went wrong, please try again
                            </Typography>
                            <IconButton
                                onClick={refetchRate}
                                size="small"
                                aria-label="refresh"
                                sx={{ ml: 2 }}
                                color={"secondary"}
                            >
                                <Refresh />
                            </IconButton>
                        </Box>
                    }
                    {apiResponse?.response && !error && (
                        <Box sx={{p: 1, bgcolor: "background.paper", display: "flex", justifyContent: "flex-end"}}>
                            <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Typography variant="h3" sx={{mr: 5}}>
                                        Rate: {apiResponse.response.rate}
                                    </Typography>
                                    {showInverseRate && (
                                        <Typography variant="body1" sx={{mr: 5, mt: 1}}>Inverse
                                            Rate: {apiResponse.response.inverse}</Typography>
                                    )}
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={() => setShowInverseRate(!showInverseRate)}
                                        size="small"
                                        aria-label="show inverse rate"
                                    >
                                        <ExpandMore/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Typography variant="body2" sx={{color: 'text.secondary', mb: 1}}>
                        {EntityId} gets
                    </Typography>
                    <NumericFormat
                        fullWidth
                        variant="outlined"
                        name="buyAmount"
                        value={formik.values.buyAmount}
                        onValueChange={(values) => {
                            handleAmountChange({name: "buyAmount", value: values.floatValue})
                        }}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                        customInput={TextField}
                        sx={textFieldStyle}
                        disabled={isLoading}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Select
                                        name="currency"
                                        value={formik.values.currency}
                                        onChange={handleCurrencyChange}
                                        displayEmpty
                                        sx={selectCurrencyStyle}
                                        disabled={!userHasGetQuoteRole || isLoading}
                                    >
                                        {userHasGetQuoteRole ? (
                                            currencyOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    <Box display="flex" alignItems="center">
                                                        <img
                                                            src={option.icon}
                                                            alt={option.label}
                                                            style={{marginRight: '10px', height: '30px'}}
                                                        />
                                                        {option.label}
                                                    </Box>
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="USD">
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={usdFlag}
                                                        alt="USD"
                                                        style={{marginRight: '10px', height: '30px'}}
                                                    />
                                                    USD
                                                </Box>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Button type="submit" fullWidth variant="contained" sx={{mt: 2}} disabled={!!error}>
                        Next
                    </Button>
                    <Button onClick={handleBack} fullWidth variant="contained" sx={{mt: 2}} color={'secondary'}>
                        Back
                    </Button>
                </Box>
            </form>
        </Container>
    )
        ;
};

CurrencyConverter.propTypes = {
    EntityId: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    onApiResponse: PropTypes.func.isRequired,
};

export default CurrencyConverter;
