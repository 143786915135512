import React, {useContext, useState} from "react";
// materialUI imports
import {Box, Button, Grid, TextField, Typography} from '@mui/material';
// project imports
import {useAuthHeaders} from "./AuthHeaders";
import CustomFormField from "../ui-component/CustomFormField";
import CustomDropdown from "../ui-component/CustomDropdown";
import {ResponseDisplay} from "./response/ResponseDisplay";
import ConfigContext from "../store/Context/ConfigContext";
import {InfoText} from "../ui-component/extended/InfoText";
import {CreateBeneficiaryTOTPModal} from "../ui-component/extended/CreateBeneficiaryTOTPModal";
import {
    ACHForm,
    ACHSchema,
    DefaultSchema,
    InterLedgerForm,
    InterLedgerSchema,
    VisaB2BForm,
    VisaB2BSchema,
} from "../ui-component/extended/CreateBeneficiaryForm";
// 3rd party imports
import axios from 'axios';
import {Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";

const CreateEntityForm = () => {
    const config = useContext(ConfigContext);
    const infoTypes = config.BENEFICIARY_TYPE || [];
    const determineSchema = (infoType) => {
        switch (infoType) {
            case 'ach':
                return ACHSchema;
            case 'visab2b':
                return VisaB2BSchema;
            case 'interLedger':
                return InterLedgerSchema;
            default:
                return DefaultSchema;
        }
    };
    const navigate = useNavigate();
    const [response, setResponse] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const apiUrl = config.API_URL;
    const authHeaders = useAuthHeaders();
    const defaultInfoType = infoTypes.length > 0 ? infoTypes[0].value : '';
    const [currentSchema, setCurrentSchema] = useState(
        determineSchema(defaultInfoType)
    );
    const clientId = sessionStorage.getItem('clientId');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleFinalSubmit = async (values) => {
        const capitalizedPostalCode = values.postalCode.toUpperCase();
        const requestData = {
            clientId: clientId,
            totp: values.totp,
            request: {
                entityId: values.entityId,
                details: {
                    type: values.type,
                    name: values.name,
                },
            }
        };

        if (values.InfoType === 'ach') {
            requestData.request.details.ach = {
                type: values.achType,
                aba: values.aba,
                account: values.account,
            };
        } else if (values.InfoType === 'visab2b') {
            requestData.request.details.visab2b = {
                bic: values.bic,
                address: {
                    street1: values.street1,
                    street2: values.street2,
                    city: values.city,
                    regionCode: values.state,
                    country: values.country,
                    postalCode: capitalizedPostalCode,
                },
                ...(values.iban && {iban: values.iban}),
                ...(values.account && {account: values.account}),
                ...(values.branch && {branch: values.branch}),
            };
        } else if (values.InfoType === 'interLedger') {
            requestData.request.details.interledger = {
                paymentPointer: values.paymentPointer,
                address: {
                    street1: values.street1,
                    street2: values.street2,
                    city: values.city,
                    regionCode: values.state,
                    country: values.country,
                    postalCode: capitalizedPostalCode,
                },
            };
        }

        const config = {
            url: `${apiUrl}/figaro/v1/beneficiary`,
            method: 'POST',
            data: requestData,
            headers: authHeaders,
        };
        try {
            const response = await axios(config);
            if (response.data.error) {
                setResponse(response.data);
                setIsModalOpen(false);
                return;
            }
            setResponse(response.data);
            setIsModalOpen(false);
            navigate('/view-beneficiary', {state: {fromCreateEntity: true}});

        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            }
            console.error('Error:', error);
        }
    };

    return (
        <Box>
            <div
                onClick={() => setShowInfo(prev => !prev)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '5px'
                }}
            >
                <Typography variant="h6" component="h2" gutterBottom style={{marginRight: '8px'}}>
                    Need Help?
                </Typography>
            </div>
            <Box sx={{overflow: 'auto'}}>
                <Formik
                    initialValues={{
                        InfoType: defaultInfoType,
                        type: "",
                        entityId: '',
                        name: '',
                        achType: 'dda', // Default ACH type
                        aba: '',
                        account: '',
                        bic: '',
                        iban: '',
                        branch: '',
                        street1: '',
                        city: '',
                        state: '',
                        country: '',
                        postalCode: '',
                        paymentPointer: '',
                        totp: '',
                    }}
                    validationSchema={currentSchema}
                    onSubmit={() => setIsModalOpen(true)}
                >
                    {({values, handleChange, handleBlur, setFieldValue, touched, errors}) => (
                        <Form>
                            {infoTypes.length > 1 ? (
                                <Grid item xs={5} sx={{mb: 1}}>
                                    <CustomDropdown
                                        name="InfoType"
                                        label="Payment System"
                                        value={values.InfoType}
                                        handleChange={(e) => {
                                            handleChange(e);
                                            if (e.target.name === 'InfoType') {
                                                setCurrentSchema(determineSchema(e.target.value));
                                                setFieldValue("InfoType", e.target.value);
                                            }
                                        }}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        options={infoTypes.map((infoType) => ({
                                            value: infoType.value,
                                            label: infoType.label,
                                        }))}
                                    />
                                    <InfoText
                                        text={
                                            "The client's ID for the beneficiary. This will need to be unique\n" +
                                            "is unique, but otherwise can be any string you want."
                                        }
                                        showInfo={showInfo}
                                    />
                                </Grid>
                            ) : (
                                <input type="hidden" name="InfoType" value={defaultInfoType}/>
                            )}
                            <Grid container spacing={1}>
                                <Grid item xs={6} lg={4}>
                                    <CustomDropdown
                                        name="type"
                                        label="Beneficiary Category "
                                        value={values.type}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        options={[
                                            {value: 'person', label: 'Person'},
                                            {value: 'company', label: 'Company'},
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={4}>
                                    <CustomFormField
                                        name="entityId"
                                        label="Beneficiary Reference"
                                        value={values.entityId}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        validateChars={true}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <CustomFormField
                                        name="name"
                                        label="Name"
                                        value={values.name}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        validateChars={true}
                                    />
                                </Grid>
                                {values.InfoType === 'ach' &&
                                    <Grid item xs={12}>
                                        <ACHForm values={values} handleChange={handleChange} handleBlur={handleBlur}
                                                 touched={touched} errors={errors}/>
                                    </Grid>
                                }
                                {values.InfoType === 'visab2b' &&
                                    <Grid item xs={12}>
                                        <VisaB2BForm values={values} handleChange={handleChange} handleBlur={handleBlur}
                                                     touched={touched} errors={errors}/>
                                    </Grid>
                                }
                                {values.InfoType === 'interLedger' &&
                                    <Grid item xs={12}>
                                        <InterLedgerForm values={values} handleChange={handleChange}
                                                         handleBlur={handleBlur}
                                                         touched={touched} errors={errors}/>
                                    </Grid>
                                }
                                <Grid xs={12} md={6} sx={{ml: 1}}>
                                    <CustomFormField
                                        name="description"
                                        label="Description"
                                        value={values.description}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                            <CreateBeneficiaryTOTPModal
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                onConfirm={handleFinalSubmit}
                                values={values}
                            />
                        </Form>
                    )}
                </Formik>
            </Box>

            {response && (
                <Box sx={{mt: 2}}>
                    <ResponseDisplay response={response} typeOfRequest={"Beneficiary Creation"}/>
                </Box>
            )}
        </Box>
    );
};

export default CreateEntityForm;