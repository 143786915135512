import React, {useContext, useState} from 'react';
import axios from 'axios';
import {useAuthHeaders} from './AuthHeaders';
import CustomFormField from '../ui-component/CustomFormField';
import {Box, Button, Grid, IconButton, Typography} from '@mui/material';
import {ResponseDisplay} from './response/ResponseDisplay';
import ConfigContext from '../store/Context/ConfigContext';
import * as Yup from 'yup';
import {Refresh as RefreshIcon} from '@mui/icons-material';
import {Form, Formik} from "formik";
import {RolesContext} from "../store/Context/RolesContext";
import {useNavigate} from "react-router-dom";

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const InviteForm = () => {
    const {roles} = useContext(RolesContext)
    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();
    const clientId = sessionStorage.getItem('clientId');
    const [response, setResponse] = useState(null);
    const [invitedUserEmail, setInvitedUserEmail] = useState("");
    const navigate = useNavigate();


    const handleSubmit = async (values, {resetForm}) => {
        try {
            const response = await axios.post(
                `${config.API_URL}/login/v1/invite`,
                {
                    realm: clientId,
                    email: values.email,
                },
                {
                    headers: authHeaders,
                }
            );
            setResponse(response.data);
            setInvitedUserEmail(values.email);
            resetForm();
        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            }
            console.error('Error sending invitation:', error);
        }
    };

    const handleRefresh = () => {
        setResponse(null);
    };
    const handleGivePermissions = () => {
        navigate('/users', { state: { email: invitedUserEmail } });
    };


    return (
        <div>
            {!response ? (
                <Formik
                    initialValues={{realm: '', email: ''}}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({values, handleChange, handleBlur, touched, errors}) => (
                        <Form>
                            <Grid container>
                                <Grid item sm={6} md={4}>
                                    <CustomFormField
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                            <Button type="submit" variant="contained" color="primary">
                                Send Invitation
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Box>
                    {response.expiry ? (
                        <>
                            <Typography variant="h3" gutterBottom>
                                User Successfully invited.
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Expiry: {response.expiry}
                            </Typography>
                            {roles.includes("grant") &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGivePermissions}>
                                    Give User Permissions
                                </Button>
                            }
                        </>
                    ) : (
                        <ResponseDisplay response={response} typeOfRequest={'Invited User'}/>
                    )}
                    <IconButton onClick={handleRefresh} color="primary">
                        <RefreshIcon/>
                    </IconButton>
                </Box>
            )}
        </div>
    );
};

export default InviteForm;