import React from 'react';
import {Grid} from "@mui/material";
import ApiRequestCard from "../ui-component/cards/ApiRequestCard";
import CreateEntityForm from "../requests/CreateEntity";

const CreateBeneficiary = () => {
    return (
        <Grid container spacing={2} justifyContent="center">
            <ApiRequestCard
                title={"Create Beneficiary"}
                buttonText={"Create Beneficiary"}
                component={ <CreateEntityForm/>}
                description={"Click the button below to create a new beneficiary."}
            />
        </Grid>
    );
}

export default CreateBeneficiary;