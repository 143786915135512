import React, {useContext, useEffect, useState} from 'react';
import {DataGrid, GridFooterContainer, GridPagination} from '@mui/x-data-grid';
import RefreshQueryToolbar from "../ui-component/datagrid/RefreshQueryToolbar";
import {useBeneficiaries} from "../store/Context/EntityContext";
import {Box, Button, Dialog, DialogContent, DialogTitle, Stack, Tooltip, Typography} from "@mui/material";
import CreateEntityModal from "../ui-component/extended/CreateEntityModal";
import useEntityDetails from "../store/Hooks/useEntityDetails";
import * as PropTypes from "prop-types";
import {renderBankDetails} from "../store/renderBankDetails";
import {useTheme} from "@mui/material/styles";
import {styled} from "@mui/system";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import {RolesContext} from "../store/Context/RolesContext";

const StyledDot = styled('div')(({ theme, status }) => {
    const baseStyle = {
        height: '10px',
        width: '10px',
        borderRadius: '50%',
        display: 'inline-block',
        animation: 'pulsate 3s infinite',
    };

    const statusColors = {
        ready: theme.palette.success.main,
        pending: theme.palette.warning.main,
        rejected: theme.palette.error.main,
        failed: theme.palette.error.main,
        disabled: theme.palette.error.main,
    };

    return {
        ...baseStyle,
        backgroundColor: statusColors[status] || 'transparent',
        '@keyframes pulsate': {
            '0%': {transform: 'scale(1)'},
            '50%': {transform: 'scale(1.2)'},
            '100%': {transform: 'scale(1)'},
        },
    };
});
const PulsatingDot = ({status}) => {
    return (
        <Tooltip title={status.charAt(0).toUpperCase() + status.slice(1)} arrow>
            <StyledDot status={status}/>
        </Tooltip>
    )
};

function BeneficiaryDetailsModal({open, handleClose, entityDetails, loading, error}) {
    const renderContent = () => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error loading details: {error.message}</p>;
        if (entityDetails) return renderBankDetails(entityDetails.details, entityDetails?.entityId);

        return <p>No details available.</p>;
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{fontWeight: 'bold', fontSize: '1.2rem'}}> {entityDetails?.entityId} Details</DialogTitle>
            <DialogContent sx={{backgroundColor: '#f5f5f5', padding: '20px'}}>
                {entityDetails && (
                    <>
                        <Box sx={{my: 2}}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle1">Type: </Typography>
                                <Typography variant="body2">
                                    {entityDetails.details?.type.charAt(0).toUpperCase() + entityDetails.details?.type.slice(1)}
                                </Typography>
                            </Stack>
                        </Box>
                    </>
                )}
                {renderContent()}
            </DialogContent>
        </Dialog>
    );
}

BeneficiaryDetailsModal.propTypes = {
    entityDetails: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    open: PropTypes.bool.isRequired
};


const EntityTable = () => {
    const {entityData, triggerReload} = useBeneficiaries();
    const [sortedData, setSortedData] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [selectedEntityId, setSelectedEntityId] = useState(null);
    const location = useLocation();
    const {roles} = useContext(RolesContext)

    const {entityDetails, error, loading} = useEntityDetails(selectedEntityId);

    const theme = useTheme();


    useEffect(() => {
        if (location.state && location.state.fromCreateEntity) {
            toast.success("Success! Beneficiary was added.", {
                position: toast.POSITION.BOTTOM_LEFT,
                theme: "colored",
            });
        }
        handleCloseCreateModal()
        triggerReload();
    }, [location.state]);

    useEffect(() => {
        const sortEntitiesByStateAndReference = (entities) => {
            const statePriority = {
                ready: 1,
                pending: 2,
                rejected: 3,
                failed: 4,
                disabled: 5
            };

            return [...entities].sort((a, b) => {
                if (statePriority[a.state] === statePriority[b.state]) {
                    return a.reference.localeCompare(b.reference);
                }
                return statePriority[a.state] - statePriority[b.state];
            });
        };

        if (Array.isArray(entityData)) {
            setSortedData(sortEntitiesByStateAndReference(entityData));
        }
    }, [entityData]);

    const handleOpenCreateModal = () => {
        setCreateModalOpen(true);
    };

    const handleCloseCreateModal = () => {
        setCreateModalOpen(false);
        triggerReload();
    };

    const handleOpenDetailsModal = () => {
        setDetailsModalOpen(true);
    };

    const handleCloseDetailsModal = () => {
        setDetailsModalOpen(false);
    };

    const handleRefresh = () => {
        triggerReload();
    };

    const handleRowClick = (params) => {
        setSelectedEntityId(params.row.reference);
        handleOpenDetailsModal();
    };
    const columns = [
        {field: 'reference', headerName: 'Beneficiary Reference', flex: 1},
        // {
        //     field: 'network',
        //     headerName: 'Beneficiary Network',
        //     flex: 1,
        //     headerAlign: 'right',
        //     align: 'right',
        // },
        {
            field: 'state',
            headerName: 'Status',
            flex: 0.25,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => (
                <PulsatingDot status={params.value}/>
            )
        },
    ];
    const CustomFooter = ({ onOpenCreateModal }) => (
        <GridFooterContainer>
            {roles.includes("beneficiary_create") &&
                <Button
                variant="contained"
                color="secondary"
                onClick={onOpenCreateModal}
                startIcon={<AddIcon />}
                sx={{
                    m: 2,
                }}
                size="small"
            >
                Create Beneficiary
            </Button>
            }
            <GridPagination />
        </GridFooterContainer>
    );

    return (
        <div style={{height: 650, width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <CreateEntityModal open={createModalOpen} handleClose={handleCloseCreateModal}/>
                <BeneficiaryDetailsModal
                    open={detailsModalOpen}
                    handleClose={handleCloseDetailsModal}
                    entityDetails={entityDetails}
                    loading={loading}
                    error={error}
                />
            </div>
            <DataGrid
                rows={Array.isArray(sortedData) ? sortedData : []}
                columns={columns}
                getRowId={(row) => row.reference}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                style={{backgroundColor: 'white'}}
                sx={{
                    boxShadow: 2,
                    borderRadius: '8px',
                    overflow: 'hidden',
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: '700',
                            fontSize: '1rem',
                        },
                    },
                }}
                slots={{
                    toolbar: () => <RefreshQueryToolbar onRefresh={handleRefresh}/>,
                    footer: () => <CustomFooter onOpenCreateModal={handleOpenCreateModal} />,

            }}
                onRowClick={handleRowClick}
            />
        </div>
    );
};

export default EntityTable;


BeneficiaryDetailsModal.propTypes = {
    entityDetails: PropTypes.any,
    handleClose: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.any,
    open: PropTypes.bool
};