import React, {createContext, useContext, useEffect, useState} from 'react';
import axios from "axios";
import {useAuthHeaders} from "../../requests/AuthHeaders";
import ConfigContext from "./ConfigContext";

const BeneficiaryContext = createContext();

export const useBeneficiaries = () => {
    return useContext(BeneficiaryContext);
};

export const BeneficiaryProvider = ({children}) => {
    const [entityData, setEntityData] = useState([]);
    const [reload, setReload] = useState(false);
    const config = useContext(ConfigContext);
    const apiUrl = config.API_URL;
    const authHeaders = useAuthHeaders();
    const clientId = sessionStorage.getItem('clientId');

    useEffect(() => {
        const getBeneficiaryData = async () => {
            try {
                const requestData = {
                    clientId: clientId
                };
                const config = {
                    url: `${apiUrl}/figaro/v1/beneficiaries`,
                    method: 'POST',
                    data: requestData,
                    headers: authHeaders,
                };

                const response = await axios(config);
                setEntityData(response.data.entities);

            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.href = '/token-expiry';
                }

            }
        };

        const jwt = sessionStorage.getItem('jwtToken');

        if (jwt && clientId) { // Only fetch data if JWT exists as well as a clinet Id
            getBeneficiaryData();
        }
    }, [apiUrl, reload]);

    const triggerReload = () => {
        setReload(!reload);
    };

    return (
        <BeneficiaryContext.Provider value={{entityData, triggerReload}}>
            {children}
        </BeneficiaryContext.Provider>
    );
};
